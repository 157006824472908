const SocketEnum = {
  OrderBook: "orderBook",
  OpenOrders: "openOrders",
  RecentTrades: "recentTrades",
  Credits: "credits",
  Tickers: "tickers",

  TestSocket: "testSocket",
}

export default SocketEnum;