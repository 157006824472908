import React, { useState, } from "react";
import "./coins-onchain-statuses.scss";
import * as DateUtil from "../../../utils/date";
import * as NumberUtil from "../../../utils/number";
import * as StringUtil from "../../../utils/string";
import CoinImageBundle from '../../../css/imgs/coins/0-bundle';


import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";

const CoinsOnchainStatuses = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);

  const sideItems = [];

  const columns = [
    {table: "coinOnchainStatuses",value: "id",hide: true, index:true, },
    {table: "coinOnchainStatuses",value: "coin", index:true,
      custom: ({v,cv}) => {
        let coin = v[cv];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
        <div className="coin-asset">
          <img src={coinSrc} />
          <div className="coin">{coin}</div>
        </div>);
      }
    },
    {table: "coinOnchainStatuses",value: "addressGeneration",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"On":"Off"}</span></div>,  },
    {table: "coinOnchainStatuses",value: "deposits",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"On":"Off"}</span></div>,  },
    {table: "coinOnchainStatuses",value: "depositConfirmations",},
    {table: "coinOnchainStatuses",value: "withdrawals",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"On":"Off"}</span></div>,  },
    {table: "coinOnchainStatuses",value: "withdrawalFeeSats", display:"Withdrawal Fee",
      custom: ({v,cv}) => {
        let amountSats = NumberUtil.satsToVal(v[cv]);
        let coin = (v['coinOnchainStatusesWithdrawalFeeCoin'])?v['coinOnchainStatusesWithdrawalFeeCoin']:v['coinOnchainStatusesCoin'];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
        <div className="coin-asset right">
          <div>{amountSats}</div>
          <div className="coin">{coin}</div>
          <img src={coinSrc} />
        </div>);
      }
    },
      // custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  },
    {table: "coinOnchainStatuses",value: "withdrawalFeeCoin", hide:true,},
    {table: "coinOnchainStatuses",value: "withdrawalMinimumSats", display:"Withdrawal Minimum",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  },
    {table: "coinOnchainStatuses",value: "depositFeeSats", display:"Deposit Fee",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  },
    {table: "coinOnchainStatuses",value: "depositMinimumSats", display:"Deposit Minimum",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  },
    {table: "coinOnchainStatuses",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "coinOnchainStatuses",value: "updatedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="coin-onchain-statuses-comp">
      <div className="coin-onchain-statuses-comp-header">
        <h3>Coin Onchain Statuses</h3>
      </div>
      <Table 
        database={"Node"}
        displayName="CoinsOnchainStatuses"
        name="CoinsOnchainStatusesTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="coin-onchain-statuses-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="coin-onchain-statuses-modal-main">
          <form>
            {showValue?Object.keys(showValue).map(function(key, index) {
              let label = StringUtil.decapitalize(key.replace("coinOnchainStatuses",""));
              let value = showValue[key]?showValue[key]:"";
              return(
                <div key={index}>
                  <label>{StringUtil.prettifyCamelCase(label)}</label>
                  <input type="text" value={value} readOnly onChange={(e)=>{
                    console.log("do stuff")
                  }}/>
                </div>
              )
            }):null}
          </form>
          {/* {console.log("showValue",showValue)} */}
          {/* "coinOnchainStatusesId": 1,
          "coinOnchainStatusesCoin": "DAWI",
          "coinOnchainStatusesAddressGeneration": 1,
          "coinOnchainStatusesDeposits": 1,
          "coinOnchainStatusesDepositConfirmations": 20,
          "coinOnchainStatusesWithdrawals": 1,
          "coinOnchainStatusesWithdrawalFeeSats": "350000",
          "coinOnchainStatusesWithdrawalMinimumSats": "120000000000",
          "coinOnchainStatusesDepositFeeSats": "0",
          "coinOnchainStatusesDepositMinimumSats": "120000000000",
          "coinOnchainStatusesCreatedDate": "2021-09-29T03:19:11.000Z",
          "coinOnchainStatusesUpdatedDate": null */}
          {/* {JSON.stringify(showValue, null, 2)} */}
        </div>
      </Modal>

    </div>
  );
};

export default CoinsOnchainStatuses;
