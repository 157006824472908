import React from "react";
import "./store-details.scss";
import CopyInput from "../../../elements/copy-input/copy-input";

const StoreDetails = ({store,load}) => {

  let arr = [];
 for(let key in store){
   let item = [];
   item.push(key);
   item.push(store[key]);
   arr.push(item);
 }

  return (
    <div className="store-details-comp">
      {arr.map((v,i) => {
        return(
          <div className="block" key={i}>
            <label>{v[0]?v[0]:""}</label>
            <CopyInput display={""} value={v[1]?v[1]:""} />
          </div>
        )
      })}
    </div>
  );
};

export default StoreDetails;
