import React,{useState, useEffect} from "react";
import "./stores-modal.scss";
import Modal from "../../common/modal/modal";
import CarouselHeader from "../../common/carousel/carousel-header";
import Carousel from "../../common/carousel/carousel";
import { clone } from "../../../utils/clone";

import Wallet from '../users/user-modal-slides/wallet';
import StoreHistory from './store-slides/store-history';
import StoreDetails from "./store-slides/store-details";
import StoreDashboard from "./store-slides/store-dashboard";
import StoreProducts from "./store-slides/store-products";


const SlideEnum = {
  StoreDetails: {Key: 1, Path: "store-details", Display: "Store Details"},
  Wallet: {Key: 2, Path: "wallet", Display: "Wallet"},
  StoreHistory: {Key: 3, Path: "store-history", Display: "Store History"},
  Dashboard: {Key: 4, Path: "dashboard", Display: "Dashboard"},
  Products: {Key: 5, Path: "products", Display: "Products"},
}

const StoresModal = ({show,handleClose,store,tickers}) => {

  const [slide,setSlide] = useState(1);
  const [slidToArray,setSlidToArray] = useState([]);

  useEffect(() => {
    let newSlidToArray = slidToArray;
    if(newSlidToArray.indexOf(slide) === -1){
      newSlidToArray = clone(slidToArray);
      newSlidToArray.push(slide);
    }
    setSlidToArray(newSlidToArray);
  },[slide,slidToArray])

  // console.log("store",store);

  let ownerId = store && store.storesId?("store_"+store.storesId):0
  let ownerUid = store && store.storesUid?("store_"+store.storesUid):0

  return (
    <Modal className={"stores-modal-comp"} show={show} handleClose={handleClose}>
      <div className="stores-modal-inner">
        <CarouselHeader className="hlo" slideTo={slide} forceResize={show}>
          <div onClick={() => { setSlide(SlideEnum.StoreDetails.Key);}}>{SlideEnum.StoreDetails.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Wallet.Key);}}>{SlideEnum.Wallet.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.StoreHistory.Key);}}>{SlideEnum.StoreHistory.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Dashboard.Key);}}>{SlideEnum.Dashboard.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Products.Key);}}>{SlideEnum.Products.Display}</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div>
            <StoreDetails store={store} load={slidToArray.indexOf(SlideEnum.StoreDetails.Key) !== -1} />
          </div>
          <div>
            <Wallet tickers={tickers} eOwnerId={ownerId} load={slidToArray.indexOf(SlideEnum.Wallet.Key) !== -1} />
          </div>
          <div>
            <StoreHistory tickers={tickers} eOwnerId={ownerId} eOwnerUid={ownerUid} load={slidToArray.indexOf(SlideEnum.StoreHistory.Key) !== -1} />
          </div>
          <div>
            <StoreDashboard store={store} load={slidToArray.indexOf(SlideEnum.Dashboard.Key) !== -1} />
          </div>
          <div>
            <StoreProducts store={store} load={slidToArray.indexOf(SlideEnum.Products.Key) !== -1} />
          </div>
        </Carousel>
      </div>
    </Modal>
  );
};

export default StoresModal;
