import * as NumberUtil from './number';
import {UsdCoins} from '../enums/coins'

export const estimateCoinValue = (coin,amountSats,tickers) => {

  let usdValueCents = 0;
  let btcValueSats = 0;

  if(coin === "BTC"){
    btcValueSats = amountSats
  }else if(coin in tickers && "BTC" in tickers[coin]){
    btcValueSats = NumberUtil.satsToVal(amountSats) * tickers[coin]["BTC"];
    btcValueSats = NumberUtil.valToSats(btcValueSats);
  }

  if(coin === "USD"){
    usdValueCents = NumberUtil.valToCents(NumberUtil.satsToVal(amountSats))
  }else if(coin in tickers && "USD" in tickers[coin]){
    usdValueCents = NumberUtil.satsToVal(amountSats) * tickers[coin]["USD"];
    usdValueCents = NumberUtil.valToCents(usdValueCents);
  }

  return {usdValueCents,btcValueSats};
}
