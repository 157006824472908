
import {Environment, EnvironmentEnum, SandboxEnvArr} from './env';

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3045",
    ApiBaseSocket: "ws://localhost:3045",
    ApiSandboxBaseUrl: "http://localhost:3046",
    ApiSandboxBaseSocket: "ws://localhost:3046",
    AppName: "dawipay",
    Company: "dawipay",
    CompanyLink: "https://dawioay.com/",
    FrontEndUrl: "http://localhost:3040",
    LiveFrontEndUrl: "http://localhost:3040",
    SandboxFrontEndUrl: "http://localhost:3041",
    ApiDocsUrl: "http://localhost:3044",
    SupportEmailName: "mail",
    SupportEmailDomain: "dawipay.com",
  },
  Keys:{
    StripePublicKey: "",
    StripeAnnualPriceId: "",
    StripeMonthlyPriceId: "",
    GoogleAnalytics: "",
    RecaptchaPubKey: "6Lf6fvccAAAAAL0eCyCXdJgXz4OVrsiKvPROqRzS",
  },
  Sandbox: false,
};


if(SandboxEnvArr.indexOf(Environment) !== -1){
  Config.Sandbox = true;
}

if(Environment === EnvironmentEnum.LocalSandbox){
  Config.Common.ApiBaseUrl = Config.Common.ApiSandboxBaseUrl;
  Config.Common.ApiBaseSocket = Config.Common.ApiSandboxBaseSocket;
  Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}

if(Environment === EnvironmentEnum.Release || Environment === EnvironmentEnum.ReleaseSandbox){
  Config.Common.LiveFrontEndUrl = "https://asurcoin.com";
  Config.Common.ApiDocsUrl = "https://docs.asurcoin.com";
  Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}

if(Environment === EnvironmentEnum.Release){
  Config.Common.ApiBaseUrl = "https://api.asurcoin.com";
  Config.Common.ApiBaseSocket = "wss://api.asurcoin.com";
  Config.Common.FrontEndUrl = "https://asurcoin.com";
}

if(Environment === EnvironmentEnum.ReleaseSandbox){
  Config.Common.ApiBaseUrl = "https://api-sandbox.asurcoin.com";
  Config.Common.ApiBaseSocket = "wss://api-sandbox.asurcoin.com";
  Config.Common.SandboxFrontEndUrl = "https://sandbox.asurcoin.com";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.ProductionSandbox){
  Config.Common.LiveFrontEndUrl = "https://dawipay.com";
  Config.Common.SandboxFrontEndUrl = "https://sandbox.dawipay.com";
  Config.Common.ApiDocsUrl = "https://docs.dawipay.com";
  Config.Keys.RecaptchaPubKey = "6Le-ffccAAAAANhI5Fl-LYbbir38zx76HQdfKLmy";
}

if(Environment === EnvironmentEnum.Production){
  Config.Common.ApiBaseUrl = "https://api.dawipay.com";
  Config.Common.ApiBaseSocket = "wss://api.dawipay.com";
  Config.Common.FrontEndUrl = "https://dawipay.com";
}

if(Environment === EnvironmentEnum.ProductionSandbox){
  Config.Common.ApiBaseUrl = "https://api-sandbox.dawipay.com";
  Config.Common.ApiBaseSocket = "wss://api-sandbox.dawipay.com";
  Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}

export default Config;
