
export const findObjectByValue = (object,value) => {
  for (let index in object){
    let pos = object[index];
    if(pos.value === value){
      return pos;
    }
  }
  return null;
}

// objects - [Object] : What you want to normalize
// value - String : What should be the value
// label - String : What the user should see
export const normalizeObjects = (objects,value,label) => {
  let newObjects = [];

  for (let index in objects){
    let object = objects[index];
    newObjects.push({
      "value": object[value],
      "label": object[label],
    })
  }

  return newObjects;
}

export const buildObjectByValues = (values,baseObject) => {
  let newObjects = [];

  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    for (let j = 0; j < baseObject.length; j++) {
      const baseObjectItem = baseObject[j];
      if(baseObjectItem.value === value){
        newObjects.push(baseObjectItem);
      }
    }
  }

  return newObjects;
}
