import React from 'react';

import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
import {ReactComponent as PeopleSvg} from "../../css/imgs/icon-people.svg";
import {ReactComponent as OutputsSvg} from "../../css/imgs/icon-outputs.svg";
import {ReactComponent as CoinsSvg} from "../../css/imgs/icon-coins.svg";
// import {ReactComponent as UserRolesSvg} from "../../css/imgs/icon-user-roles.svg";
import {ReactComponent as CustomerSupportSvg} from '../../css/imgs/icon-customer-support.svg';
import {ReactComponent as IssuesSvg} from '../../css/imgs/icon-q-a.svg';
import {ReactComponent as DepositSvg} from '../../css/imgs/icon-deposit.svg';
import {ReactComponent as ChainSvg} from '../../css/imgs/icon-chain.svg';
import {ReactComponent as WithdrawalSvg} from '../../css/imgs/icon-withdrawal.svg';
import {ReactComponent as WalletSvg} from '../../css/imgs/icon-wallet.svg';
import {ReactComponent as FeedbackSvg} from '../../css/imgs/icon-feedback.svg';
import {ReactComponent as CoinPairSvg} from '../../css/imgs/icon-swap.svg';
import {ReactComponent as MoneySvg} from '../../css/imgs/icon-money.svg';
import {ReactComponent as ToolsSvg} from '../../css/imgs/icon-tools.svg';
import {ReactComponent as StoreSvg} from '../../css/imgs/icon-store.svg';


import AccountStatusEnum from '../../enums/account-status';

const naviRoutes = [
  {
    "name": "dashboard-nav-item", 
    "display": "Dashboard", 
    "path": "/dashboard",
    "icon": <DashboardSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "developer-nav-item", 
    "display": "Developer", 
    "path": "/developer",
    "icon": <ToolsSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "totals-nav-item", 
    "display": "Totals", 
    "desc": "Trade & Node",
    "path": "/totals",
    "icon": <MoneySvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "users-nav-item", 
    "display": "Users", 
    "desc": "Main Database",
    "path": "/users",
    "icon": <PeopleSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  // {
  //   "name": "user-roles-nav-item", 
  //   "display": "User Roles", 
  //   "desc": "Main Database",
  //   "path": "/user-roles",
  //   "icon": <UserRolesSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  {
    "name": "stores-nav-item", 
    "display": "Stores", 
    "desc": "Merchant Database",
    "path": "/stores",
    "icon": <StoreSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "credits-nav-item", 
    "display": "Credits", 
    "desc": "Trade Database",
    "path": "/credits",
    "icon": <WalletSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "deposits-nav-item", 
    "display": "Deposits", 
    "desc": "Node Database",
    "path": "/deposits",
    "icon": <DepositSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "withdrawals-nav-item", 
    "display": "Withdrawals", 
    "desc": "Trade/Node Database",
    "path": "/withdrawals",
    "icon": <WithdrawalSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },

  {
    "name": "outputs-nav-item", 
    "display": "Outputs", 
    "desc": "Node Database",
    "path": "/outputs",
    "icon": <OutputsSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "coin-pairs-nav-item", 
    "display": "Coin Pairs", 
    "desc": "Trade Database",
    "path": "/coin-pairs",
    "icon": <CoinPairSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "coins-nav-item", 
    "display": "Coins", 
    "desc": "Trade Database",
    "path": "/coins",
    "icon": <CoinsSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "coins-onchain-statuses-nav-item", 
    "display": "Coins Onchain", 
    "desc": "Node Database",
    "path": "/coins-onchain-statuses",
    "icon": <ChainSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "customer-support-nav-item", 
    "display": "Customer Support", 
    "path": "/customer-support",
    "icon": <CustomerSupportSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "merchant-issues-nav-item", 
    "display": "Merchant Issues", 
    "path": "/merchant-issues",
    "icon": <IssuesSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "feedback-nav-item", 
    "display": "Feedback", 
    "desc": "Other Messages too",
    "path": "/feedback",
    "icon": <FeedbackSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  
  // {
  //   "name": "test", 
  //   "display": "Test",
  //   "path": "/test",
  //   "minStatus": AccountStatusEnum.Staff,
  // },
];

export default naviRoutes;