import React, { useState, } from "react";
import "./coins.scss";
import * as DateUtil from "../../../utils/date";
// import * as NumberUtil from "../../../utils/number";
// import * as StringUtil from "../../../utils/string";
import CoinImageBundle from '../../../css/imgs/coins/0-bundle';


import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";

const Coins = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);
  
  const sideItems = [];

  const columns = [
    {table: "coins",value: "id",hide: true, index:true,},
    {table: "coins",value: "coin", index:true,
      custom: ({v,cv}) => {
        let coin = v[cv];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
        <div className="coin-asset">
          <img src={coinSrc} />
          <div className="coin">{coin}</div>
        </div>);
      }
    },
    {table: "coins",value: "label", index:true,},
    {table: "coins",value: "trading",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"True":"False"}</span></div>,  },
    {table: "coins",value: "hidden",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"True":"False"}</span></div>,  },
    {table: "coins",value: "comingSoon",
      custom: ({v,cv}) => <div className={(v[cv])?"on":"off"}><span>{(v[cv])?"True":"False"}</span></div>,  },
    {table: "coins",value: "coinOrder",},
    {table: "coins",value: "defaultTradingPair",
      custom: ({v,cv}) => {
        try{
          let pair = v[cv];
          let split = pair.split("-");
          let coin1 = split[0];
          let coin2 = split[1];
  
          let coinSrc1 = "";
          if(coin1 in CoinImageBundle){
            coinSrc1 = CoinImageBundle[coin1];
          }
          let coinSrc2 = "";
          if(coin2 in CoinImageBundle){
            coinSrc2 = CoinImageBundle[coin2];
          }
          return(
          <div className="coin-asset right">
            <img src={coinSrc1} />
            <div className="coin">{pair}</div>
            <img src={coinSrc2} />
          </div>);
        }catch(e){
          console.log("e",e);
          return <div>failed</div>
        }
      }
    },
    {table: "coins",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "coins",value: "listedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,   
    }, 
    {table: "coins",value: "updatedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="coins-comp">
      <div className="coins-comp-header">
        <h3>Coins</h3>
      </div>
      <Table 
        database={"Trade"}
        displayName="Coins"
        name="CoinsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="coins-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="coins-modal-main">
          <pre>
          {JSON.stringify(showValue, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Coins;
