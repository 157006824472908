import React, { useState, useEffect } from "react";
import "./credits.scss";
import * as NumberUtil from "../../../utils/number";
import CoinImageBundle from '../../../css/imgs/coins/0-bundle';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import {FrozenCreditReasonEnum} from '../../../enums/frozen-credit-reason';
import * as TickersUtil from '../../../utils/tickers';
import {UsdCoins} from '../../../enums/coins';

import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";
import Button from "../../elements/button/button";
import {CoinEnum} from "../../../enums/coins";

import useSocketState from '../../../store/socket';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

import { request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import CopyInput from "../../elements/copy-input/copy-input";

const Credits = () => {

  const [loading,setLoading] = useState(false);
  const [slide,setSlide] = useState(1);
  const [frozenTableInc,setFrozenTableInc] = useState(0);

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [tickers,setTickers] = useState({});

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Tickers] = SocketEnum.Tickers;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newTickers = tickers;

    try{
      if(socketMessages){
        if(SocketEnum.Tickers in socketMessages){
          let tickerRes = socketMessages[SocketEnum.Tickers][SocketEnum.Tickers];
          newTickers = tickerRes.res[SocketEnum.Tickers];
        }
      }
    }catch(e){
      console.log("catch error e",e);
    }
    
    setTickers(newTickers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages]);


  const unfreezeCredits = (uid) => {

    let data = {
      uid
    }
    setLoading(true);
    request("admin-unfreeze-credits","/admin-unfreeze-credits","POST", data, {
      then: function(res){
        toast.success("Credits Unfroze");
        setFrozenTableInc(frozenTableInc + 1)
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }


  
  const sideItems = [
    {display: "All", conditions: []},
  ];
  const frozenCreditsSideItems = [
    {display: "All", conditions: []},
  ]
  for (let key in CoinEnum) {
    const coin = CoinEnum[key];
    let coinSrc = "";
    if(coin.Coin in CoinImageBundle){
      coinSrc = CoinImageBundle[coin.Coin];
    }

    let condition = {table:"credits",column: "coin",action:"=",value: coin.Coin,}

    sideItems.push({
      display: 
      <div className={"coin-left "+coin.Coin}>
        <img src={coinSrc}/>
        <div>{coin.Coin}</div>
      </div>, 
      conditions:[condition]
    });

    let frozenCondition = {table:"frozenCredits",column: "coin",action:"=",value: coin.Coin,}
    frozenCreditsSideItems.push({
      display: 
      <div className={"coin-left "+coin.Coin}>
        <img src={coinSrc}/>
        <div>{coin.Coin}</div>
      </div>, 
      conditions:[frozenCondition]
    });
  }

  const columns = [
    {table: "credits",value: "id",hide: true, index:true, },
    {table: "credits",value: "owner", ignore:true,  ignoreRowClick: true,
      custom: ({v,cv}) => {
        let ownerName = v['ownerName'];
        let ownerId = v['creditsOwnerId'];
        return(
          <div>
            <CopyInput value={ownerName}/>
            <CopyInput value={ownerId}/>
          </div>
        )
      }, 
    },
    {table: "credits",value: "ownerId", index:true, hide:true, },
    {table: "credits",value: "coin", index:true, 
      custom: ({v,cv}) => {
        let coin = v[cv];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
          <div className="coin-asset">
            <img src={coinSrc} />
            <div className="coin">{coin}</div>
          </div>
        );
      }
    },
    {table: "credits",value: "coin", display:"Amount", index:true, ignore:true,
      custom: ({v,cv}) => {
        let coin = v['creditsCoin'];
        let totalAmountSats = parseInt(v['creditsAvailableSats']) + parseInt(v['creditsFrozenSats'])
        let availableAmountSats = v['creditsAvailableSats'];
        let frozenAmountSats = v['creditsFrozenSats'];

        let totalRes = TickersUtil.estimateCoinValue(coin, totalAmountSats, tickers);
        let availRes = TickersUtil.estimateCoinValue(coin, availableAmountSats, tickers);
        let frozenRes = TickersUtil.estimateCoinValue(coin, frozenAmountSats, tickers);


        return(
          <div className="coin-credits">
            <div className="amount-box">
              <div className="text">Frozen</div>
              <div className="number">{NumberUtil.satsToVal(frozenAmountSats)} {coin}</div>
              {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(frozenRes.btcValueSats)} BTC</div>:null}
              {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(frozenRes.usdValueCents)} USD</div>:null}
            </div>
            <div className="amount-box">
              <div className="text">Available</div>
              <div className="number">{NumberUtil.satsToVal(availableAmountSats)} {coin}</div>
              {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(availRes.btcValueSats)} BTC</div>:null}
              {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(availRes.usdValueCents)} USD</div>:null}
            </div>
            <div className="amount-box">
              <div className="text">Total</div>
              <div className="number">{NumberUtil.satsToVal(totalAmountSats)} {coin}</div>
              {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(totalRes.btcValueSats)} BTC</div>:null}
              {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(totalRes.usdValueCents)} USD</div>:null}
            </div>
          </div>
        );
      }
    },
    {table: "credits",value: "availableSats", display:"Available", hide:true,
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "credits",value: "frozenSats", display:"Frozen", hide:true,
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "credits",value: "createdDate", type:"date", index:true, hide:false,},
    {table: "credits",value: "updatedDate", type:"date", index:true, hide:false,},
  ]

  const frozenCreditsColumns = [
    {table: "frozenCredits",value: "uid", hide:true, index:true, },
    {table: "frozenCredits",value: "ownerId", ignore:true,  ignoreRowClick: true,
      custom: ({v,cv}) => {
        let ownerId = v[cv];
        return(
          <div>
            <CopyInput value={ownerId}/>
          </div>
        )
      }, 
    },
    {table: "frozenCredits",value: "coin", index:true,
      custom: ({v,cv}) => {
        let coin = v[cv];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
          <div className="coin-asset">
            <img src={coinSrc} />
            <div className="coin">{coin}</div>
          </div>
        );
      }
    },
    {table: "frozenCredits",value: "coin", display:"Amount", index:true, ignore:true,
      custom: ({v,cv}) => {
        let coin = v['frozenCreditsCoin'];
        let amountSats = parseInt(v['frozenCreditsAmountSats']);
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);

        return(
          <div className="coin-credits">
            <div className="amount-box">
              <div className="text">Frozen</div>
              <div className="number">{NumberUtil.satsToVal(amountSats)} {coin}</div>
              {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
              {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
            </div>
          </div>
        );
      }
    },
    {table: "frozenCredits",value: "amountSats", display:"Amount", hide:true,
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
  
    {table: "frozenCredits",value: "reason", index:true,},
    {table: "frozenCredits",value: "reasonId",},
    {table: "frozenCredits",value: "creditUnfreezeCostSats", display:"creditUnfreezeCost", hide:true,
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },

    {table: "frozenCredits",value: "createdDate", type:"date", index:true, },
    {table: "frozenCredits",value: "releaseDate", type:"date", index:true, },
    {table: "frozenCredits",value: "action", display:"Action", ignore:true, ignoreRowClick: true,
      custom: ({v,cv}) => {
        let uid = v['frozenCreditsUid'];
        let reason = v['frozenCreditsReason'];
        if(reason !== FrozenCreditReasonEnum.Affiliate && reason !== FrozenCreditReasonEnum.Cashback){
          return "---";
        }

        return(
          <div className="unfreeze-button">
            <Button status={loading?"loading":""} onClick={() => {
              unfreezeCredits(uid);
            }}>Unfreeze</Button>
          </div>
        )
      },  
    },
  ]


  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="credits-comp">
      <div className="credits-comp-header">
        <h3>Credits</h3>
      </div>
      <CarouselHeader slideTo={slide}>
        <div onClick={() => { setSlide(1);}}>Credits</div>
        <div onClick={() => { setSlide(2);}}>Frozen Credits</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>
          <Table 
            searchEndpoint={"/admin-search/credits"}
            database={"Trade"}
            displayName="Credits"
            name="CreditsTable"
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              setShowValue(value);
              setShowModal(true);
            }}
            columns={columns}
            limit={250}
            />
        </div>
        <div>
          <Table 
            // searchEndpoint={"/admin-search/credits"}
            database={"Trade"}
            displayName="Frozen Credits"
            name="FrozenCreditsTable"
            sideItems={frozenCreditsSideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              setShowValue(value);
              setShowModal(true);
            }}
            refreshInc={frozenTableInc}
            columns={frozenCreditsColumns}
            limit={250}
            />
        </div>
      </Carousel>
      

      <Modal className="credits-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="credits-modal-main">
          <pre>
          {JSON.stringify(showValue, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Credits;
