import React,{useState,useEffect,useRef} from 'react';
import './issue-modal.scss';
import { request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Modal from '../../common/modal/modal';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import * as DateUtil from '../../../utils/date';
import {clone} from '../../../utils/clone';
import Loading from '../../common/loading/loading';

const IssueModal = ({show,handleClose,side,paymentUid}) => {

  const [loading,setLoading] = useState(false);
  const [fLoading,setFloading] = useState(false);
  const [issue,setIssue] = useState(null);
  const [messages,setMessages] = useState([]);
  const [value,setValue] = useState("");
  const divRef = useRef(null);

  const [escalated,setEscalated] = useState(false);
  const [resolved,setResolved] = useState(false);

  useEffect(() => {
    setMessages([]);
  },[paymentUid]);

  useEffect(() => {
    fetch(paymentUid);
  },[paymentUid]);

  useEffect(() => {
    return () => {
      unSubRequest("fetch-issue-details");
      unSubRequest("add-issue-message");
    }
  },[])

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  const fetch = (paymentUid) => {
    if(!paymentUid) return;

    setFloading(true);
    request("fetch-issue-details","/payments/fetch-issue-details/"+paymentUid,"GET", {}, {
      then: function(res){
        setIssue(res.data.res.issue);
        setMessages(res.data.res.messages);
        setEscalated((res.data.res.issue && res.data.res.issue.escalated)?true:false);
        setResolved((res.data.res.issue && res.data.res.issue.resolved)?true:false);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setFloading(false);}
    });
  }

  const submitHandle = (e) => {
    e.preventDefault();
    addNewMessage(paymentUid,side);
  }

  const addNewMessage = (paymentUid,side) => {
    let body = {
      value: value,
      side,
    }
    setLoading(true);
    request("add-issue-message","/payments/add-issue-message/"+paymentUid,"POST", body, {
      then: function(res){
        let newMessages = clone(messages);
        newMessages.push({
          value: value,
          side,
          createdDate: new Date(),
        });
        setMessages(newMessages);
        setValue("");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  let hostMsg = "";
  hostMsg += "An issue with this payment has been opened. ";
  hostMsg += "A chat has been opened between the Customer and Merchant. ";
  hostMsg += "Please attempt to resolve the issue here. ";
  hostMsg += "If a resolution cannot be found, you may escalate the issue to Dawipay. ";
  hostMsg += "At which point Dawipay will attempt to resolve the issue for both parties. ";

  let hostSecondMsg = "Please do not include any personal or sensitive information here. ";

  let msgs = clone(messages);
  if(msgs.length){
    msgs.unshift({
      side:"host",
      value: hostSecondMsg,
      createdDate: issue && issue.createdDate?issue.createdDate:new Date(),
    })
    msgs.unshift({
      side:"host",
      value: hostMsg,
      createdDate: issue && issue.createdDate?issue.createdDate:new Date(),
    })
  }

  const buttonClickHandle = (body) => {
    setLoading(true);
    request("issue-handler","/issue-handler/"+paymentUid,"POST", body, {
      then: function(res){
        toast.success("Updated");
        fetch(paymentUid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }
  

  return (
    <Modal className="issue-modal" show={show} handleClose={handleClose}>
      <div className="issue-modal-sub">
        <h3>Issue Resolution Center</h3>
        <div className="header-buttons">
          <div className="left-buttons">
            {(side === "customer")?<Button disabled={resolved} type="button" onClick={() => {
              buttonClickHandle({paymentUid,issueType:"resolve"});
            }}>{(resolved)?"Resolved":"Mark Resolved"}</Button>:null}
            {(side === "customer")?<Button disabled={escalated || resolved} type="button" onClick={() => {
              buttonClickHandle({paymentUid,issueType:"escalate"});
            }}>{(escalated)?"Escalated":"Escalate Issue"}</Button>:null}
            {(side === "host")?<Button disabled={resolved} type="button" onClick={() => {
              buttonClickHandle({paymentUid,issueType:"admin-resolve"});
            }}>{(resolved)?"Resolved":"Admin Resolve"}</Button>:null}
            {(side === "host")?<Button disabled={resolved} type="button" onClick={() => {
              buttonClickHandle({paymentUid,issueType:"assign"});
            }}>{(issue && issue.staffUserId)?("Assigned to UserId:"+issue.staffUserId):"Assign to Me"}</Button>:null}
          </div>
          <div className="right-buttons">
          {/* {(side === "host")?<Button disabled={resolved} type="button" onClick={() => {
            console.log("admin refund");
          }}>Force Refund</Button>:null}
          {(side === "merchant")?<Button disabled={resolved} type="button" onClick={() => {
            console.log("offer refund");
          }}>Offer Refund</Button>:null} */}
          </div>
        </div>
        <div className="messages">
          {fLoading?<div className="loading-container"><Loading show={true} /></div>:null}
          {msgs.map((v,i) => {
            let sideClass = (side === v.side)?"right":"left"
            let sideText = v.side;
            if(v.side === "host"){
              sideText = "Dawipay";
            }else if(v.side === "merchant"){
              sideText = issue.storeName;
            }else if(v.side === "customer"){
              sideText = "Customer";
            }
            return(
              <div className={"message "+sideClass+" "+v.side} key={i}>
                <div className="side">
                  {sideText}
                </div>
                <TextareaAutosize disabled={true} value={v.value}/>
                <div className="date">{DateUtil.dateTimeSince(v.createdDate)}</div>
              </div>
            )
          })}
          <div className="last" ref={divRef}></div>
        </div>
        <div className="new-message">
          <form onSubmit={submitHandle}>
            <TextareaAutosize disabled={loading || (issue && issue.resolved)} value={value} onChange={(e) => {setValue(e.target.value)}}/>
            <Button disabled={issue && issue.resolved} status={loading?"loading":""} type="submit" value="Send" />
          </form>
        </div>
      </div>
    </Modal>
  );

};

export default IssueModal;