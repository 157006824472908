import React,{useState,useEffect} from 'react';
import './wallet.scss';
import {request,unSubRequest} from '../../../../utils/request';
import {toast} from 'react-toastify';
import CoinImageBundle from '../../../../css/imgs/coins/0-bundle';
import * as NumberUtil from '../../../../utils/number';
import Settings from '../../../../config/settings';
import CopyInput from '../../../elements/copy-input/copy-input';
import {EthereumTokens} from '../../../../enums/coins';

const BlockChairCoinNameEnum = {
  DASH: "dash",
  LTC: "litecoin",
  BTC: "bitcoin",
  BCH: "bitcoin-cash",
  ETH: "ethereum",
  DOGE: "dogecoin",
}

const Wallet = ({eOwnerId,load,tickers}) => {

  const [loading,setLoading] = useState(false);
  const [ownerId,setOwnerId] = useState(0);

  const [listedCoins,setListedCoins] = useState([]);
  const [credits,setCredits] = useState({});
  const [addresses,setAddresses] = useState({});

  useEffect(() => {
    let newOwnerId = ownerId;
    if(eOwnerId && load && ownerId !== eOwnerId){
      newOwnerId = eOwnerId;
    }
    setOwnerId(newOwnerId);
  },[eOwnerId,load,ownerId])

  useEffect(() => {
    fetchBalance(ownerId);
    return () => {
      unSubRequest("admin-balance");
    }
  },[ownerId]);

  const fetchBalance = (ownerId) =>{
    if(ownerId === 0) return;
    
    let endpoint = "/balance/user/"+ownerId;

    // console.log("endpoint",endpoint);
    setLoading(true);
    request("admin-balance",endpoint,"GET", {}, {
      then: function(res){
        // console.log("admin-balance-res",res.data.res);

        setListedCoins(res.data.res.listedCoins);
        setCredits(res.data.res.credits);
        setAddresses(res.data.res.userCoinAddresses)

        // setOwnerWithdrawalStatus(res.data.res.withdrawalStatus);
        // setCoinStatuses(res.data.res.coinStatuses);
        // setWithdrawalLimit(res.data.res.withdrawalLimit);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }


  let totalUsd = 0;
  let totalBtc = 0;
  for (let key in credits) {
    const credit = credits[key];
    if(key in tickers && "USD" in tickers[key]){
      totalUsd += parseFloat(credit.balance) * tickers[key]["USD"];
    }

    if(key === "BTC"){
      totalBtc += parseFloat(credit.balance)
    }else if(key === "USDC" && "BTC" in tickers && "USDC" in tickers["BTC"]){
      totalBtc += parseFloat(credit.balance) / tickers["BTC"]["USDC"];
    }else if(key === "USDC" && "BTC" in tickers && "USDT" in tickers["BTC"]){
      totalBtc += parseFloat(credit.balance) / tickers["BTC"]["USDT"];
    }else if(key in tickers && "BTC" in tickers[key]){
      totalBtc += parseFloat(credit.balance) * tickers[key]['BTC'];
    }
  }

  totalUsd = Math.round(totalUsd*100)/100;
  totalBtc = Math.round(totalBtc*100000000)/100000000;

  let coins = [];
  for (let i = 0; i < listedCoins.length; i++) {
    const listedCoin = listedCoins[i];
    let coin = listedCoin.coin

    let available = (credits[coin])?credits[coin].available:0
    let balance = (credits[coin])?credits[coin].balance:0
    let frozen = (credits[coin])?credits[coin].frozen:0

    available = parseFloat(available);
    balance = parseFloat(balance);
    frozen = parseFloat(frozen);

    let availableValue = 0;
    let balanceValue = 0;
    let frozenValue = 0;

    if(coin in tickers && "USD" in tickers[coin]){
      availableValue = available * tickers[coin]['USD'];
      balanceValue = balance * tickers[coin]['USD'];
      frozenValue = frozen * tickers[coin]['USD'];
    }
    availableValue = Math.round(availableValue*100)/100;
    balanceValue = Math.round(balanceValue*100)/100;
    frozenValue = Math.round(frozenValue*100)/100;

    let obj = listedCoin;
    obj.available = available;
    obj.balance = balance;
    obj.frozen = frozen;
    obj.availableValue = availableValue;
    obj.balanceValue = balanceValue;
    obj.frozenValue = frozenValue;

    coins.push(obj);
  }

  coins.sort((a, b) => {
    return ((a['balanceValue'] <= b['balanceValue']) ? 1 : -1);
  });

  // console.log("totalBtc",totalBtc);

  return (
    <div className="user-wallet-comp">
      <div className="balance-header">
        <div className="total-value">
          <div className="text">Account Value</div>
          <div className="values">
            <div>
              <div className="value">${NumberUtil.formatDollar(totalUsd)}</div>
              <div className="label">USD</div> 
            </div>
            <div>
              <div className="value">₿{(isNaN(totalBtc))?"":NumberUtil.numberWithCommas(totalBtc)}</div>
              <div className="label">BTC</div> 
            </div>
          </div>
        </div>
      </div>
      <div className="coins">
        {(loading && listedCoins.length === 0)?
          Settings.LoadingCoinsArray.map((v,i) => {
            return (
              <div className="coin-item loading-item loading-item-flow" key={i}>

              </div>
            )
          }):null
        }
        {
          coins.map((v,i) => {
            console.log("addresses",addresses);

            let address = "";
            let lookupCoin = v.coin;
            console.log("lookupCoin",lookupCoin);
            if(addresses){
              console.log("one");
              if(EthereumTokens.indexOf(v.coin) !== -1 && "ETH" in addresses){
                lookupCoin = "ETH";
                address = addresses["ETH"][0];
              }else if(v.coin in addresses){
                console.log("two");
                address = addresses[v.coin][0];
              }
            }

            let coinPath = v.coin in BlockChairCoinNameEnum?BlockChairCoinNameEnum[lookupCoin]:null;
            let url = "https://blockchair.com/"+coinPath+"/address/"+address

            let frozenSats = NumberUtil.valToSats(v.frozen);
            let frozenClassClickable = (frozenSats > 0)?true:false
            let frozenClass = (frozenClassClickable)?"clickable":"";

            return (
              <div className={"coin-item "+((v.comingSoon)?"coming-soon":"")} key={i}>
                <div className="coin-detail">
                  <div className="icon">
                    <img alt={v.coin+" coin"} src={(CoinImageBundle[v.coin])?CoinImageBundle[v.coin]:null}/>
                  </div>
                  <div className="text">
                    <div className="coin">{v.coin}</div>
                    <div className="label">{v.label?v.label:""}</div>
                  </div>
                </div>

                <div className="coin-value">
                  <div className={"frozen "+frozenClass} onClick={() => {
                    if(!frozenClassClickable) return;

                    toast.info("Do nothing");

                  }}>
                    <div className="label">Frozen</div>                         
                    <div className="amount">{NumberUtil.numberWithCommas(v.frozen)}</div>
                    <div className="value">${NumberUtil.formatDollar(v.frozenValue)}</div>
                  </div>
                  <div className="available">
                    <div className="label">Available</div>                         
                    <div className="amount">{NumberUtil.numberWithCommas(v.available)}</div>
                    <div className="value">${NumberUtil.formatDollar(v.availableValue)}</div>
                  </div>
                  <div className="balance">
                    <div className="label">Total</div>                         
                    <div className="amount">{NumberUtil.numberWithCommas(v.balance)}</div>
                    <div className="value">${NumberUtil.formatDollar(v.balanceValue)}</div>
                  </div>
                </div>
                
                <div className="actions">
                  <CopyInput display={""} value={address} />
                  <a href={url} target="_blank" rel="noreferrer">View on BlockExplorer</a>
                </div>
                
              </div>
            )
          })
        }
      </div>
    </div>
  );

};

export default Wallet;