import { useEffect } from 'react';
// import {useGlobalState} from './store';
import {useGlobal} from 'reactn';
import useUserState from '../store/user';
import {toast} from 'react-toastify';
import Config from '../config/config';
import SocketStateEnum from '../enums/socket-state';
import SocketEventEnum from '../enums/socket-event';

// Using sessionStorage for some state here since we need to use some state simultaneously on load
const useSocketState = () => {

  const {user} = useUserState();
  const [socket, setSocket] = useGlobal('socket'); 
  const [socketClientId, setSocketClientId] = useGlobal('socketClientId'); 
  const [socketMessages, setSocketMessages] = useGlobal('socketMessages'); 
  const [restartSocket,setRestartSocket] = useGlobal('restartSocket');

  // useEffect(() => {
    // console.log("is it me?")
  // },[socketMessages]);

  useEffect(() => {
    if(restartSocket && socket){
      socket.close();
      setRestartSocket(false);
      setSocket(null);
      openSocket(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[restartSocket,socket,user,])

  const sendSocketMessage = (data) => {
    let sessionSocket = getSessionSocketState();
    sessionSocket.queue.push(data);
    sessionStorage.setItem("socket",JSON.stringify(sessionSocket));
    if(sessionSocket.state === SocketStateEnum.Open){
      processQueue(socket);
    }else{
      openSocket();
    }
  }

  const processQueue = (socket) => {
    let sessionSocketState = getSessionSocketState();

    let queue = sessionSocketState.queue;
    if(queue.length && socket){
      for (let i = 0; i < queue.length; i++) {
        const data = queue[i];
        if(data.event === SocketEventEnum.Subscribe){
          if(user && user.uid && user.auth){
            let passUser = {
              uid: user.uid,
              auth: user.auth,
            }
            data.user = passUser;
          }
          for(let key in data.channels){
            sessionSocketState.channels[key] = data.channels[key]; 
          }
        }else if(data.event === SocketEventEnum.Unsubscribe){
          for(let key in data.channels){ 
            delete sessionSocketState.channels[key]; 
          }
        }
        socket.send(JSON.stringify(data));
      }

      sessionSocketState.queue = [];
      sessionStorage.setItem("socket",JSON.stringify(sessionSocketState));
    }

    sessionSocketState = getSessionSocketState();
  }


  const openSocket = (userArg) => {
    userArg = userArg === undefined?user:userArg;

    if(socket) return false;
    let sessionSocket = getSessionSocketState();
    if(sessionSocket.state !== SocketStateEnum.Closed){
      return false;
    }

    let newSocket = new WebSocket(Config.Common.ApiBaseSocket);

    sessionSocket.state = SocketStateEnum.Connecting;
    sessionStorage.setItem("socket",JSON.stringify(sessionSocket));

    // If Socket does not connect within 20 seconds, close it.
    let checkOpenTimeout = setTimeout(() => {
      let ssCheck = getSessionSocketState();
      ssCheck.state = SocketStateEnum.Closed;
      sessionStorage.setItem("socket",JSON.stringify(ssCheck));
      try{
        console.log("checkOpenTimeout closed");
        newSocket.close();
      }catch(e){
        console.log("checkOpenTimeout could not close socket");
      }
    },20000);

    let keepAliveTime = new Date().getTime();
    let interval = null;
    newSocket.addEventListener('open', function (event) {
      clearTimeout(checkOpenTimeout);
      let data = {
        event: SocketEventEnum.OpenSocket,
        user: userArg,
      }
      newSocket.send(JSON.stringify(data));
      setSocket(newSocket);
      let sessionSocketState = getSessionSocketState();
      sessionSocketState.state = SocketStateEnum.Open;
      sessionStorage.setItem("socket",JSON.stringify(sessionSocketState));
      processQueue(newSocket);

      keepSocketAliveFunc(newSocket,interval);
      interval = setInterval(() => {
        keepSocketAliveFunc(newSocket,interval);
      },3000);
    });

    newSocket.addEventListener('message', function (event) {
      try{
        let data = JSON.parse(event.data);
        // console.log("data",data);
        if(data.err.code){
          toast.error(data.err.message);
          newSocket.close();
        }
        let result = data.res;
        if(data.keepAlive){
          // console.log("keepAlive Api");
          keepAliveTime = new Date().getTime();
          result = null;
        }
        if(data.clientId){
          setSocketClientId(data.clientId);
        }
        setSocketMessages(result);
      }catch(e){
        console.log("could not parse socket response");
        console.log("event.data",event.data);
      }
    });
    newSocket.addEventListener('close', function (event) {
      let sessionSocketState = getSessionSocketState();

      // console.log("websocket closed,  do we need to reopen? sessionSocketState:",sessionSocketState);   
      setSocket(null);
      if(interval !== null){
        // console.log("turn off keepsocketAlive")
        clearInterval(interval);
      }

      sessionSocketState.state = SocketStateEnum.Closed;
      sessionStorage.setItem("socket",JSON.stringify(sessionSocketState));
      if(sessionSocketState.queue.length || Object.keys(sessionSocketState.channels).length){
        if(Object.keys(sessionSocketState.channels).length){
          let newChannels = {};
          for(let key in sessionSocketState.channels){
            newChannels[key] = sessionSocketState.channels[key];
          }
          let data = {
            event: SocketEventEnum.Subscribe,
            channels: newChannels,
          }
          sessionSocketState.queue.push(data);
          sessionSocketState.channels = {};
          sessionStorage.setItem("socket",JSON.stringify(sessionSocketState));
        }

        let userLocal = localStorage.getItem('user');
        if(userLocal){
          try{
            userLocal = JSON.parse(userLocal);
          }catch(e){
            console.log("could not parse userLocal");
          }
        }

        setTimeout(() => {
          openSocket(userLocal);
        },2500);
      }
    });

    let disconnectInterval = setInterval(() => {
      let timenow = new Date().getTime() - 6400;
      if(keepAliveTime < timenow){
        console.log("No feedback from API socket, disconnect");
        newSocket.close();
        clearInterval(disconnectInterval);
      } 
    },3000);
  }

  const keepSocketAliveFunc = (socket,interval) => {
    let timenow = new Date().getTime();
    let json = {
      event: SocketEventEnum.KeepAlive,
      timenow: timenow,
    };
    if(socket.readyState !== SocketStateEnum.Open){
      // console.log("keepSocketAlive socket not open, exiting");
      clearInterval(interval);
      return;
    }
    // console.log("keepAlive Front");
    socket.send(JSON.stringify(json));
  }

  const getSessionSocketState = () => {
    let sessionSocket = sessionStorage.getItem("socket");
    if(!sessionSocket){
      sessionSocket = {
        queue: [],
        channels: {},
        state: SocketStateEnum.Closed,
      }
      sessionStorage.setItem("socket",JSON.stringify(sessionSocket));
    }else{ 
      sessionSocket = JSON.parse(sessionSocket);
    }
    return sessionSocket;
  }

  // useEffect(() => {
  //   if(socket === null){
  //     let timenow = new Date();
  //     console.log("Should ask to reconnect",timenow);
  //     openSocket(user);
  //   }
  // },[socket,user])

  return {
    socketClientId,
    socketMessages,
    sendSocketMessage,
    socket,
  };
}
export default useSocketState;
