import React, { useState, useEffect } from "react";
import './withdrawals.scss';
import * as NumberUtil from "../../../utils/number";
import * as TickersUtil from '../../../utils/tickers';
import {UsdCoins} from '../../../enums/coins';

import Table from '../../elements/table/table';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';

import useSocketState from '../../../store/socket';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

import CoinImageBundle from '../../../css/imgs/coins/0-bundle';
import {CoinEnum} from "../../../enums/coins";
import CopyInput from "../../elements/copy-input/copy-input";

const Withdrawals = () => {

  const [slide,setSlide] = useState(1);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [tickers,setTickers] = useState({});
  
  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Tickers] = SocketEnum.Tickers;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newTickers = tickers;

    try{
      if(socketMessages){
        if(SocketEnum.Tickers in socketMessages){
          let tickerRes = socketMessages[SocketEnum.Tickers][SocketEnum.Tickers];
          newTickers = tickerRes.res[SocketEnum.Tickers];
        }
      }
    }catch(e){
      console.log("catch error e",e);
    }
    
    setTickers(newTickers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages])

  const sideItems1 = [{display: "All", conditions:[]},];
  const sideItems2 = [{display: "All", conditions:[]},];
  const sideItems3 = [{display: "All", conditions:[]},];

  for (let key in CoinEnum) {
    const coin = CoinEnum[key];
    let coinSrc = "";
    if(coin.Coin in CoinImageBundle){
      coinSrc = CoinImageBundle[coin.Coin];
    }
    let obj1 = {
      display: 
      <div className={"coin-left "+coin.Coin}>
        <img src={coinSrc}/>
        <div>{coin.Coin}</div>
      </div>, 
      conditions:[{table:"withdrawalRequests",column:"coin", action: "=",value: coin.Coin,}]
    }
    let obj2 = {
      display: 
      <div className={"coin-left "+coin.Coin}>
        <img src={coinSrc}/>
        <div>{coin.Coin}</div>
      </div>, 
      conditions:[{table:"withdrawals",column:"coin", action: "=",value: coin.Coin,}]
    }
    let obj3 = {
      display: 
      <div className={"coin-left "+coin.Coin}>
        <img src={coinSrc}/>
        <div>{coin.Coin}</div>
      </div>, 
      conditions:[{table:"withdrawalTransactions",column:"coin", action: "=",value: coin.Coin,}]
    }

    sideItems1.push(obj1);
    sideItems2.push(obj2);
    sideItems3.push(obj3);

  }


  const withdrawalRequestsColumns = [
    {table: "withdrawalRequests",value: "ownerId", index:true, 
      custom: ({v,cv}) => {
        let ownerId = v[cv];
        return(
          <div>
            <CopyInput value={ownerId} />  
          </div>
        )
      }, 
    },
    {table: "withdrawalRequests",value: "coin", index:true, hide:true,  },
    {table: "withdrawalRequests",value: "sendFeeCoin", index:true, hide:true,  },
    {table: "withdrawalRequests",value: "sendAmountSats", display:"Send Amount",
      custom: ({v,cv}) => {
        let coin = v.withdrawalRequestsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    {table: "withdrawalRequests",value: "sendFeeSats", display:"Send Fee",
      custom: ({v,cv}) => {
        let coin = v.withdrawalRequestsSendFeeCoin?v.withdrawalRequestsSendFeeCoin:v.withdrawalRequestsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    {table: "withdrawalRequests",value: "address", type:"copy-input" },
    {table: "withdrawalRequests",value: "createdDate", type:"date", index:true, defaultOrder:"DESC",},
    {table: "withdrawalRequests",value: "transported", hide:true,  },
    {table: "withdrawalRequests",value: "transportedDate", type:"date" },
    {table: "withdrawalRequests",value: "txId", type:"copy-input" },
    {table: "withdrawalRequests",value: "txConfirmed", hide:true,  },
    {table: "withdrawalRequests",value: "txConfirmedDate", type:"date",},
    {table: "withdrawalRequests",value: "tompleted", hide:true,   },
    {table: "withdrawalRequests",value: "completedDate", type:"date",},

  ];

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]



  const withdrawalColumns = [
    {table: "withdrawals",value: "id", },
    {table: "withdrawals",value: "withdrawalRequestId",  hide:true, },
    {table: "withdrawals",value: "withdrawalTransactionId",  hide:true, },
    {table: "withdrawals",value: "ownerId", 
      custom: ({v,cv}) => {
        let ownerId = v[cv];
        return(
          <div>
            <CopyInput value={ownerId} />  
          </div>
        )
      }, 
    },
    {table: "withdrawals",value: "coin", hide:true, index:true },
    {table: "withdrawals",value: "amountSats", display:"Amount",
      custom: ({v,cv}) => {
        let coin = v.withdrawalsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },

    {table: "withdrawals",value: "address", type:"copy-input" },
    {table: "withdrawals",value: "createdDate", type:"date", display:"Date", index:true, defaultOrder:"DESC",},
    {table: "withdrawals",value: "processed", hide:true,},
    {table: "withdrawals",value: "processedDate", type:"date", },
    {table: "withdrawals",value: "blockchainConfirmed", hide:true,},
    {table: "withdrawals",value: "blockchainConfirmedDate", display:"Blockchain Conf Date", type:"date", },
    {table: "withdrawals",value: "txId", type:"copy-input" },
    {table: "withdrawals",value: "transported", hide:true, },
    {table: "withdrawals",value: "transportedDate", type:"date" },
  ];

  const withdrawalTransactionsColumns = [
    {table: "withdrawalTransactions",value: "id", index:true,},
    {table: "withdrawalTransactions",value: "coin", index:true, },
    {table: "withdrawalTransactions",value: "amountOutSats", display:"Amount Out",
      custom: ({v,cv}) => {
        let coin = v.withdrawalTransactionsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },  
    },
    {table: "withdrawalTransactions",value: "amountReturnSats", display:"Amount Return",
      custom: ({v,cv}) => {
        let coin = v.withdrawalTransactionsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    {table: "withdrawalTransactions",value: "FeeSats", display:"Fee",
      custom: ({v,cv}) => {
        let coin = v.withdrawalTransactionsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    // {table: "withdrawalTransactions",value: "rawHash", type:"copy-input" },
    // {table: "withdrawalTransactions",value: "wallet", },
    {table: "withdrawalTransactions",value: "createdDate", type:"date", display:"Date", index:true, defaultOrder:"DESC",},
    {table: "withdrawalTransactions",value: "signedHexBool", hide:true,},
    {table: "withdrawalTransactions",value: "signedHex", type:"copy-input"},
    {table: "withdrawalTransactions",value: "signedHexDate", type:"date",},
    {table: "withdrawalTransactions",value: "broadcasted", hide:true,},
    {table: "withdrawalTransactions",value: "broadcastedTxId", type:"copy-input"},
    {table: "withdrawalTransactions",value: "broadcastedDate", display:"Broadcasted Date", type:"date", },
    {table: "withdrawalTransactions",value: "broadcastedConfirmed", hide:true,},
    {table: "withdrawalTransactions",value: "broadcastedConfirmedDate", display:"Broadcasted Conf Date", type:"date", },
    {table: "withdrawalTransactions",value: "blockchainConfirmed", hide:true,},
    {table: "withdrawalTransactions",value: "blockchainConfirmedDate", display:"Blockchain Conf Date", type:"date", },

  ];


  return (
    <div className="withdrawals-comp">
      <div className="withdrawals-comp-header">
        <h3>Withdrawals</h3>
        <h6>Amounts here are calculated at present time value</h6>
      </div>
      <CarouselHeader slideTo={slide}>
        <div onClick={() => { setSlide(1);}}>Withdrawal Requests (Trade)</div>
        <div onClick={() => { setSlide(2);}}>Withdrawals (Node)</div>
        <div onClick={() => { setSlide(3);}}>Withdrawal Transactions (Node)</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>
          <Table 
            database={"Trade"}
            displayName="Withdrawal Requests (Trade)"
            name="WithdrawalRequestsTradeTable"
            sideItems={sideItems1}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {

            }}
            columns={withdrawalRequestsColumns}
            limit={250}
            />
        </div>
        <div>
          <Table 
            database={"Node"}
            displayName="Withdrawals (Node)"
            name="WithdrawalsNodeTable"
            sideItems={sideItems2}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {

            }}
            columns={withdrawalColumns}
            limit={250}
            />
        </div>
        <div>
          <Table 
            database={"Node"}
            displayName="Withdrawal Transactions (Node)"
            name="WithdrawalTransactionsNodeTable"
            sideItems={sideItems3}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {

            }}
            columns={withdrawalTransactionsColumns}
            limit={250}
            />
        </div>
      </Carousel>
      

    </div>
  );
};

export default Withdrawals;
