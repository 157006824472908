
const FrozenCreditReasonEnum = {
  Credit: "Credit",
  Order: "Order",
  Withdrawal: "Withdrawal",
  Cashback: "Cashback",
  Affiliate: "Affiliate",
  System: "System",
};

module.exports = {
  FrozenCreditReasonEnum,
};