import React from "react";

const StoreDashboard = () => {

  return (
    <div className="store-dashboard-comp">
      store-dashboard-comp
    </div>
  );
};

export default StoreDashboard;
