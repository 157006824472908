
export const dateTimeSinceEpoch = function (epoch,nowSeconds) {
  let foo = parseInt(epoch*1000)
  let d = new Date(foo);
  return dateTimeSince(d,nowSeconds);
}

export const dateTimeSinceWithTime = (dateObj,nowSeconds) => {return dateTimeSince(dateObj,nowSeconds,true)}
export const dateTimeSince = function (dateObj,nowSeconds,withTime) {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let nowSecs = (nowSeconds)?nowSeconds:5;


  let d = new Date(dateObj);
  let t = new Date();
  let dateDiff = t.getTime() - d.getTime();

  var secondsFromD2T = dateDiff / 1000;
  var secondsBetweenDates = Math.abs(secondsFromD2T);

  let beforeOrAfter = secondsFromD2T > 1?"before":"after";


  let text = "";
  if(secondsBetweenDates <= nowSecs){
    return "Now";
  }else if(secondsBetweenDates <= 60){
    let seconds = Math.floor(secondsBetweenDates)
    text = ((seconds !== 1)? seconds+" Seconds":seconds+" Second");
  }else if(secondsBetweenDates < 3600){
    let minutes = Math.floor(secondsBetweenDates/60);
    text = ((minutes > 1)? minutes+" Minutes":minutes+" Minute");
  }else if(secondsBetweenDates < 86400){
    let hours = Math.floor(secondsBetweenDates/60/60);
    text = ((hours > 1)? hours+" Hours":hours+" Hour");
  }else if(secondsBetweenDates < 604800){//7 days
    let days = Math.floor(secondsBetweenDates/60/60/24);
    text = ((days > 1)? days+" Days":days+" Day");
  }

  if(text !== ""){
    return (beforeOrAfter === "before")?(text+" Ago"):("In "+text)
  }

  if(withTime){
    return prettifyDateWithTime(d);
  }
  return prettifyDate(d);
};

export const prettifyDate = (dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let d = new Date(dateObj);
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 
                'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let formattedDate = months[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear()
  return formattedDate;
}

export const prettifyDateToTime = (dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let d = new Date(dateObj);
  let formattedDate = "";
  formattedDate += (d.getUTCHours() <= 9)?"0"+d.getUTCHours():d.getUTCHours();
  formattedDate += ":";
  formattedDate += (d.getUTCMinutes() <= 9)?"0"+d.getUTCMinutes():d.getUTCMinutes();
  formattedDate += ":";
  formattedDate += (d.getUTCSeconds() <= 9)?"0"+d.getUTCSeconds():d.getUTCSeconds();
  return formattedDate;
}

export const prettifyDateWithTime = (dateObj) => {
  if (dateObj === null || dateObj === undefined || dateObj === ""){
    return "---";
  }

  let foo = prettifyDate(dateObj);
  let bar = prettifyDateToTime(dateObj);

  return foo+" "+bar;
}

export const epochToDatetimeLocal = (epochTime,offsetTime) => {
  let minute1 = 60000;
  let hour1 = minute1*60;
  let time = parseInt(epochTime);
  time = time - (time % minute1)
  if(offsetTime){
    time = time + (((new Date().getTimezoneOffset())/60) * hour1);
  }
  time = new Date(time);
  time = time.getFullYear()+"-"+(((time.getMonth()+1) <= 9)?"0":"")+(time.getMonth()+1)+"-"+((time.getDate()<=9)?"0":"")+time.getDate()+"T"+((time.getHours()<=9)?"0":"")+time.getHours()+":00";
  return time
}

export const datetimeToEpoch = (date,offsetTime) => {
  let minute1 = 60000;
  let hour1 = minute1*60;
  let epoch = new Date(date).getTime();
  if(offsetTime){
    epoch = epoch - (((new Date().getTimezoneOffset())/60) * hour1);
  }
  return epoch
}