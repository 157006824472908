import React, {useState, useEffect} from 'react';
import './wallet-history.scss';
import * as NumberUtil from "../../../../utils/number";
import * as StringUtil from "../../../../utils/string";
import * as DateUtil from "../../../../utils/date";
import {clone} from "../../../../utils/clone";
import {FrozenCreditReasonEnum} from '../../../../enums/frozen-credit-reason';

import Table from '../../../elements/table/table';
import CarouselHeader from '../../../common/carousel/carousel-header';
import Carousel from '../../../common/carousel/carousel';
import SwitchToggle from '../../../elements/switch-toggle/switch-toggle';

import CopyInput from '../../../elements/copy-input/copy-input';
import { toast } from 'react-toastify';

const SlideEnum = {
  FrozenCredits: {Key: 1, Path: "frozen-credits", Display: "Frozen Credits"},
  OpenOrders: {Key: 2, Path: "open-orders", Display: "Open Orders"},
  Deposits: {Key: 3, Path: "deposits", Display: "Deposits"},
  Withdrawals: {Key: 4, Path: "withdrawals", Display: "Withdrawals"},
  Trades: {Key: 5, Path: "trades", Display: "Trades"},
  Subscriptions: {Key: 6, Path: "subscriptions", Display: "Subscriptions"},
  Payments: {Key: 7, Path: "payments", Display: "Payments"},
  Credit: {Key: 8, Path: "credit", Display: "Credit"},
}

const StoreSlideEnum = {
  FrozenCredits: clone(SlideEnum.FrozenCredits),
  OpenOrders: clone(SlideEnum.OpenOrders),
  Withdrawals: clone(SlideEnum.Withdrawals),
  Trades: clone(SlideEnum.Trades),
}

StoreSlideEnum.FrozenCredits.Key = 1;
StoreSlideEnum.OpenOrders.Key = 2;
StoreSlideEnum.Withdrawals.Key = 3;
StoreSlideEnum.Trades.Key = 4;

const BlockChairCoinNameEnum = {
  DASH: "dash",
  LTC: "litecoin",
  BTC: "bitcoin",
  BCH: "bitcoin-cash",
  ETH: "ethereum",
  DOGE: "dogecoin",
}

const WalletHistory = ({eOwnerId,load,tickers}) => {

  const [slide,setSlide] = useState(1);
  const [slidToArray,setSlidToArray] = useState([]);

  const [ownerId,setOwnerId] = useState(0);
  const [refreshInc,setRefreshInc] = useState(0);

  useEffect(() => {
    let newOwnerId = ownerId;
    let newRefreshInc = refreshInc
    if(eOwnerId && load && eOwnerId !== ownerId){
      newOwnerId = eOwnerId;
      newRefreshInc = newRefreshInc + 1;
    }
    setRefreshInc(newRefreshInc);
    setOwnerId(newOwnerId);
  },[eOwnerId,load,refreshInc,ownerId])

  useEffect(() => {
    let newSlidToArray = slidToArray;
    if((newSlidToArray.indexOf(slide) === -1) && load){
      newSlidToArray = clone(slidToArray);
      newSlidToArray.push(slide);
    }
    setSlidToArray(newSlidToArray);
  },[slide,slidToArray,load])

  const depositColumns = [
    {table: "deposits",value: "coin", index:true,},
    {table: "deposits",value: "valueSats",display:"Amount",type:"varcharNumber", 
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "deposits",value: "txId",  type:"copy-input",},
    {table: "deposits",value: "createdDate", display:"Date", type:"datetime", index:true, defaultOrder:"DESC", },
  ];

  const withdrawalColumns = [
    {table: "withdrawalRequests",value: "coin", index:true, },
    {table: "withdrawalRequests",value: "totalAmountSats", display:"Total Amount", type:"varcharNumber",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "withdrawalRequests",value: "sendAmountSats", display:"Send Amount", type:"varcharNumber",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "withdrawalRequests",value: "sendFeeSats", display:"Send Fee", type:"varcharNumber",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "withdrawalRequests",value: "address",
      custom: ({v,cv}) => {
        let coin = v['withdrawalRequestsCoin'];
        let coinPath = coin in BlockChairCoinNameEnum?BlockChairCoinNameEnum[coin]:null;
        let address = (v[cv])?v[cv]:""
        let url = "https://blockchair.com/"+coinPath+"/address/"+address
        return(
            <div className='address-box'>
              <CopyInput display="" value={(v[cv])?v[cv]:""}/>
              {coinPath?<a href={url} target="_blank" rel="noreferrer">View Address on Explorer</a>:null}
            </div>
          )
        },  
    },
    {table: "withdrawalRequests",value: "createdDate",display:"Request Date",type:"date",index:true, defaultOrder:"DESC", },
    {table: "withdrawalRequests",value: "txId",index:true,
      custom: ({v,cv}) => {
        let coin = v['withdrawalRequestsCoin'];
        let coinPath = coin in BlockChairCoinNameEnum?BlockChairCoinNameEnum[coin]:null;
        let txid = (v[cv])?v[cv]:""
        let url = "https://blockchair.com/"+coinPath+"/transaction/"+txid;
        return(
          <div className='txid-box'>
            <CopyInput display="" value={(v[cv])?v[cv]:""}/>
            {(txid && coinPath)?<a href={url} target="_blank" rel="noreferrer">View Tx on Explorer</a>:null}
          </div>
        )
      },  
    },
    {table: "withdrawalRequests",value: "completed",index:true,
      custom: ({v,cv}) => <div>{(v[cv])?"True":"False"}</div>,  
    },
    {table: "withdrawalRequests",value: "completedDate",type:"date",},
  ]

  const tradeColumns = [
    {table: "tradeHistory",value: "side", display:"Details",
      custom: ({v,cv}) => {
        let side = v.tradeHistorySide;
        let makerOrTaker = v.tradeHistoryMakerOrTaker;
        // if(makerOrTaker === "taker"){
        //   side = (side === "buy")?side = "sell":side = "buy";
        // }
        return(
          <div className='order-tails'>
            <div>{StringUtil.capitalize(side)} Order</div>
            <div>{StringUtil.capitalize(makerOrTaker)}</div>
          </div>
        )
      }, 
    },
    {table: "tradeHistory",value: "coinPair", index:true, },
    {table: "tradeHistory",value: "makerOrTaker",ignore:true,hide:true,},
    {table: "tradeHistory",value: "amountSats", display:"Amount",  type:"varcharNumber",
      custom: ({v,cv}) => {
        let coinPair = v.tradeHistoryCoinPair;
        let baseAsset = coinPair.split("-")[0];
        let value = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v[cv]));
        let btcValue = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v["tradeHistoryBtcValueSats"]));
        let usdValue = NumberUtil.formatDollar(NumberUtil.centsToVal(v["tradeHistoryUsdValueCents"]));
        return(
          <div className='amount'>
            <div className='main'>{value} {baseAsset}</div>
            <div className='sub'>~ {btcValue} {"BTC"}</div>
            <div className='sub'>~ {usdValue} {"USD"}</div>
          </div>
        )
      },  
    },
    {table: "tradeHistory",value: "priceSats", display:"Price", type:"varcharNumber",
      custom: ({v,cv}) => {
        let coinPair = v.tradeHistoryCoinPair;
        let quoteAsset = coinPair.split("-")[1];
        let value = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v[cv]));
        return(
          <div className='amount'>
            <div className='main'>{value} {quoteAsset}</div>
          </div>
        )
      },  
    },
    {table: "tradeHistory",value: "feeCoin",ignore:true, hide:true,},
    {table: "tradeHistory",value: "feeAmountSats", display:"Fee", ignore:true,
      custom: ({v,cv}) => {
        let coin = v.tradeHistoryFeeCoin;
        let value = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v[cv]));
        let secondCoin = null;
        let secondValue = null;
        if(v["tradeHistorySecondFeeCoin"] && v["tradeHistorySecondFeeAmountSats"]){
          secondCoin = (v["tradeHistorySecondFeeCoin"]);
          secondValue = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v["tradeHistorySecondFeeAmountSats"]));
        }
        return(
          <div className='amount'>
            <div className='main'>{value} {coin}</div>
            {(secondCoin && secondValue)?
              <div className='main'>{secondValue} {secondCoin}</div>
            :null}
          </div>
        )
      },  
    },
    {table: "tradeHistory",value: "receiveCoin",ignore:true, hide:true,},
    {table: "tradeHistory",value: "receiveAmountSats", display:"Received", ignore:true,
      custom: ({v,cv}) => {
        let coin = v.tradeHistoryReceiveCoin;
        let value = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v[cv]));
        let secondCoin = null;
        let secondValue = null;
        if(v["tradeHistorySecondReceiveCoin"] && v["tradeHistorySecondReceiveAmountSats"]){
          secondCoin = (v["tradeHistorySecondReceiveCoin"]);
          secondValue = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v["tradeHistorySecondReceiveAmountSats"]));
        }
        return(
          <div className='amount'>
            <div className='main'>{value} {coin}</div>
            {(secondCoin && secondValue)?
              <div className='main'>{secondValue} {secondCoin}</div>
            :null}
          </div>
        )
      },   
    },
    {table: "tradeHistory",value: "createdDate",display:"Date",type:"date", index:true, defaultOrder:"DESC",},
  ];

  const paymentColumns = [
    {table: "payments",value: "uid", hide:true},
    {table: "payments",value: "storeId",display:"storeUid", hide:true},
    {table: "payments",value: "productId",display:"productUid", hide:true},
    {table: "stores",value: "name", display:"Store Name", 
      custom: ({v,cv}) => {
        return(
          <div className='stpre-info'>
            <div className='store-name'>{v.storesName}</div>
            <div className='product-name'>{v.productsName}</div>
          </div>
        )
      }
    },
    {table: "products",value: "name", display:"Product Name", hide:true},
    {table: "payments",value: "coin", hide:true, index:true, },
    {table: "payments",value: "totalAmountSats", hide:true, },
    {table: "payments",value: "totalUsdAmountCents", hide:true, },
    {table: "payments",value: "totalBtcAmountSats", hide:true, },
    {table: "payments",value: "cashbackAmountSats", hide:true, },
    {table: "payments",value: "cashbackUsdAmountCents", hide:true, },
    {table: "payments",value: "cashbackBtcAmountSats", hide:true, },
    {table: "payments",value: "total", display:"Total", ignore: true,
      custom: ({v,cv}) => {
        const coin = v.paymentsCoin;
        let totalAmount = NumberUtil.satsToVal(v.paymentsTotalAmountSats);
        const btcAmount = NumberUtil.satsToVal(v.paymentsTotalBtcAmountSats);
        const usdAmount = NumberUtil.formatDollar(NumberUtil.centsToVal(v.paymentsTotalUsdAmountCents));
        if(coin === "USD" || coin === "USDT" || coin === "USDC"){
          totalAmount = NumberUtil.formatDollar(parseFloat(totalAmount));
        }
        return(
          <div className='total-amounts'>
            <div className='main'>{totalAmount} {coin}</div>
            {(coin !== "BTC")?
              <div className='sub'> ~ {btcAmount} BTC</div>
            :null}
            {(coin !== "USD" && coin !== "USDT" && coin !== "USDC")?
              <div className='sub'> ~ {usdAmount} USD</div>
            :null} 
          </div>
        )
      } 
    },
    {table: "payments",value: "cashback", display:"Cashback", ignore: true,
      custom: ({v,cv}) => {
        const coin = v.paymentsCoin;
        let totalAmount = NumberUtil.satsToVal(v.paymentsCashbackAmountSats);
        const btcAmount = NumberUtil.satsToVal(v.paymentsCashbackBtcAmountSats);
        const usdAmount = NumberUtil.formatDollar(NumberUtil.centsToVal(v.paymentsCashbackUsdAmountCents));
        if(coin === "USD" || coin === "USDT" || coin === "USDC"){
          totalAmount = NumberUtil.formatDollar(parseFloat(totalAmount));
        }
        return(
          <div className='total-amounts'>
            <div className='main'>{totalAmount} {coin}</div>
            {(coin !== "BTC")?
              <div className='sub'> ~ {btcAmount} BTC</div>
            :null}
            {(coin !== "USD" && coin !== "USDT" && coin !== "USDC")?
              <div className='sub'> ~ {usdAmount} USD</div>
            :null} 
            </div>
        )
      } 
    },
    
    {table: "payments",value: "createdDate",display:"Date",type:"date", index:true, defaultOrder:"DESC", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSinceWithTime(v[cv])}</div>,  
    },
    {table: "payments",value: "issueStatus",display:"Issues",
      custom: ({v,cv}) => {
        let issueStatus = v.paymentsIssueStatus?v.paymentsIssueStatus:0;
        return(
          <div className='report-a-problem-div'>
            <div>Issue Status: {issueStatus}</div>
          </div>
        )
      },  
    },
    {table: "payments", value: "completed", hide:true,},
    {table: "payments", value: "refundStatus",display:"Status",
      custom: ({v,cv}) => {
        let refundStatus = v.paymentsRefundStatus?v.paymentsRefundStatus:0;
        let completed = v.paymentsCompleted?v.paymentsCompleted:0;
        return(
          <div className='status-div'>
            <div>Refund Status: {refundStatus}</div>
            <div>Completed: {completed}</div>
          </div>
        )
      },  
    },
  ]

  const paymentJoinOptions = [
    {joinType:"RIGHT JOIN",leftTable:"payments",rightTable:"stores",leftColumn:"storeId",rightColumn:"id",},
    {joinType:"RIGHT JOIN",leftTable:"payments",rightTable:"products",leftColumn:"productId",rightColumn:"id",},
  ]


  const subscriptionsLeftItems = [{
      display: "Active",
      conditions:[{
        value: 1,
        action: "=",
        column:"active", 
        table:"subscriptions",
      }]
    },{
      display: "Inactive",
      conditions:[{
        value: 0,
        action: "=",
        column:"active", 
        table:"subscriptions",
      }]
  }]

  const subscriptionsColumns = [
    {table: "subscriptions",value: "uid", hide:true, index:true,},
    {table: "subscriptions",value: "storeId", hide:true},
    {table: "subscriptions",value: "productId", hide:true},
    {table: "subscriptions",value: "priceId", hide:true},
    {table: "stores",value: "name", display:"Store Details", 
      custom: ({v,cv}) => {
        return(
          <div className='stpre-info'>
            <div className='store-name'>{v.storesName}</div>
            <div className='product-name'>{v.productsName}</div>
          </div>
        )
      }
    },
    {table: "products",value: "name", display:"Product Name", hide:true },
    {table: "subscriptions",value: "userBasePayCoin", display:"Coin", index:true,},
    {table: "prices",value: "cost", display:"Price", 
      custom: ({v,cv}) => {
        let cost = v.pricesCost;
        let baseCoin = v.pricesBaseCoin;
        let durationCounter = v.pricesDurationCounter;
        let durationValue = v.pricesDurationValue
        let duration = (durationValue === 1)?durationCounter:"per "+durationValue+" "+durationCounter+"s";
        return(
          <div>{cost} {baseCoin}/{duration}</div>
        )
      }
    },
    {table: "prices",value: "baseCoin", hide:true, },
    {table: "prices",value: "durationCounter", hide:true, },
    {table: "prices",value: "durationValue", hide:true, },
    {table: "subscriptions",value: "active", index:true, hide:true,},
    {table: "subscriptions",value: "nextBillingDate",display:"Next Billing Date",type:"date", index:true, 
      custom: ({v,cv}) => {
        let renew = v.subscriptionsRenew?true:false;
        return(
          <div>{renew?DateUtil.dateTimeSince(v[cv]):"---"}</div>
        )
      },  
    },
    {table: "subscriptions",value: "createdDate",display:"Start Date",type:"date", index:true, defaultOrder:"DESC", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "subscriptions",value: "renew", display:"Auto Renew",
      custom: ({v,cv}) => <SwitchToggle isOn={v.subscriptionsRenew?true:false} onClickFunc={() => {toast.info("do nothing")}} />,
    },
  ]

  const subscriptionsJoinOptions = [
    {joinType:"RIGHT JOIN",leftTable:"subscriptions",rightTable:"stores",leftColumn:"storeId",rightColumn:"id",},
    {joinType:"RIGHT JOIN",leftTable:"subscriptions",rightTable:"products",leftColumn:"productId",rightColumn:"id",},
    {joinType:"RIGHT JOIN",leftTable:"subscriptions",rightTable:"prices",leftColumn:"priceId",rightColumn:"id",},
  ]

  const creditColumns = [
    {table: "creditPayOffs",value: "coin", index:true, },
    {table: "creditPayOffs",value: "usdValueCents", hide:true,} ,
    {table: "creditPayOffs",value: "btcValueSats", hide:true,} ,
    {table: "creditPayOffs",value: "AmountSats", display:"Amount", type:"varcharNumber",
      custom: ({v,cv}) => {
        let coin = v['creditPayOffsCoin']
        let amount = NumberUtil.satsToVal(v[cv]);
        let usdValue = NumberUtil.centsToVal(v['creditPayOffsUsdValueCents']);
        let btcValue = NumberUtil.satsToVal(v['creditPayOffsBtcValueSats']);
        return(
          <div className='amount'>
            <div>{amount} {coin}</div>
            <div className='sub'>{usdValue} USD</div>
            <div className='sub'>{btcValue} BTC</div>
          </div>
        )
      },  
    },
    {table: "creditPayOffs",value: "createdDate",display:"Date",type:"date",index:true, defaultOrder:"DESC", 
      // custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]

  const openOrderColumns = [
    {table: "orders",value: "id", index:true, },
    {table: "orders",value: "coinPair", index:true,} ,
    {table: "orders",value: "side", index:true,} ,
    {table: "orders",value: "type", },
    {table: "orders",value: "amountSats", display:"Amount",
      custom: ({v,cv}) => {
        let baseAsset = v["ordersCoinPair"].split("-")[0]; 
        let amount = NumberUtil.satsToVal(v[cv]);
        return(
          <div className='amount'>
            <div>{amount} {baseAsset}</div>
          </div>
        )
      },  
    },
    {table: "orders",value: "priceSats", display:"Price",
      custom: ({v,cv}) => {
        let quoteAsset = v["ordersCoinPair"].split("-")[1]; 
        let price = NumberUtil.satsToVal(v[cv]);
        return(
          <div className='price'>
            <div>{price} {quoteAsset}</div>
          </div>
        )
      },  
    },
    {table: "orders",value: "frozenCoin", hide:true},
    {table: "orders",value: "frozenAmountSats", display:"Frozen Amount",
      custom: ({v,cv}) => {
        let coin = v["ordersFrozenCoin"];
        let price = NumberUtil.satsToVal(v[cv]);
        return(
          <div className='price'>
            <div>{price} {coin}</div>
          </div>
        )
      },  
    },
    {table: "orders",value: "createdDate",display:"Date",type:"date",index:true, defaultOrder:"DESC", 
      // custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]


  const frozenCreditsSideItems = [{
    display: "All",
    conditions:[],
  }];

  for(let key in FrozenCreditReasonEnum){
    let value = FrozenCreditReasonEnum[key];
    frozenCreditsSideItems.push({
      display: value,
      conditions:[
        { action: "=", column:"reason", table:"frozenCredits",value: value, }
      ],
    })
  }

  const frozenCreditsColumns = [
    {table: "frozenCredits",value: "uid", hide:true, index:true,},
    {table: "frozenCredits",value: "coin",index:true, },
    {table: "frozenCredits",value: "amountSats", display:"Amount",
      custom: ({v,cv}) => {
        let value = NumberUtil.numberWithCommas(NumberUtil.satsToVal(v[cv]));
        return(<div className='amount'>{value} {v.frozenCreditsCoin}</div>)
      }
    },
    {table: "frozenCredits",value: "reason", },
    {table: "frozenCredits",value: "createdDate", display:"Frozen Date", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "frozenCredits",value: "creditUnfreezeCostSats", hide:true, },
    {table: "frozenCredits",value: "releaseDate", display:"Reward Date",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "frozenCredits",value: "payoff", display:"Actions", ignore: true,
      custom: ({v,cv}) => {
        if(v["frozenCreditsReason"] !== "Credit"){
          return <div>---</div>
        }
        let creditUnfreezeCostSats = NumberUtil.satsToVal(v['frozenCreditsCreditUnfreezeCostSats']);
        let coin = v['frozenCreditsCoin'];
        let usdValue = NumberUtil.formatDollar(creditUnfreezeCostSats);
        let btcValue = creditUnfreezeCostSats;

        if(coin in tickers && "USD" in tickers[coin]){
          usdValue = NumberUtil.valFloatPointFix(creditUnfreezeCostSats * tickers[coin]["USD"]);
          usdValue = NumberUtil.formatDollar(usdValue);
        }else if(coin !== "USD"){
          usdValue = "---";
        }

        if(coin in tickers && "BTC" in tickers[coin]){
          btcValue = NumberUtil.valFloatPointFix(creditUnfreezeCostSats * tickers[coin]["BTC"])
        }else if(coin !== "BTC"){
          btcValue = "---";
        }
        
        return(
          <div className='frozen-credits-pay-off'>
            <div className='value'>
              <div className='ammount'>{creditUnfreezeCostSats} {coin}</div>
              <div className='ammount'>~ ${usdValue}</div>
              <div className='ammount'>~ ฿{btcValue}</div>
            </div>
          </div>
        )
      },  
    },
  ];

  let userId = 0;
  if(ownerId && ownerId !== 0){
    userId = parseInt(ownerId.replace("user_",""));
  }

  return (
    <div className="wallet-history-comp">
      <CarouselHeader className="entry-options hlo" slideTo={slide}>
        <div onClick={() => {setSlide(SlideEnum.FrozenCredits.Key)}}>Frozen Credits</div>
        <div onClick={() => {setSlide(SlideEnum.OpenOrders.Key)}}>Open Orders</div>
        <div onClick={() => {setSlide(SlideEnum.Deposits.Key)}}>Deposits</div>
        <div onClick={() => {setSlide(SlideEnum.Withdrawals.Key)}}>Withdrawals</div>
        <div onClick={() => {setSlide(SlideEnum.Trades.Key)}}>Trades</div>
        <div onClick={() => {setSlide(SlideEnum.Subscriptions.Key)}}>Subscriptions</div>
        <div onClick={() => {setSlide(SlideEnum.Payments.Key)}}>Payments</div>
        <div onClick={() => {setSlide(SlideEnum.Credit.Key)}}>Credit Payoffs</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.FrozenCredits.Key) !== -1}
            database={"Trade"}
            displayName="Frozen"
            name="FrozenTable"
            searchEndpoint={"/wallet/frozen/"+ownerId}
            sideItems={frozenCreditsSideItems}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={[]}
            rowOnClickFunc={(value) => {}}
            columns={frozenCreditsColumns}
            limit={20}
            refreshInc={refreshInc}
          />
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.OpenOrders.Key) !== -1}
            database={"Trade"}
            searchEndpoint={"/admin-history/open-orders/"+userId}
            displayName="OpenOrders"
            name="OpenOrdersTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={[]}
            columns={openOrderColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.Deposits.Key) !== -1}
            database={"Node"}
            searchEndpoint={"/admin-history/deposits/"+userId}
            displayName="Deposits"
            name="DepositsTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            columns={depositColumns}
            disableAdvancedSearch={true}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.Withdrawals.Key) !== -1}
            database={"Trade"}
            searchEndpoint={"/admin-history/withdrawals/"+userId}
            displayName="Withdrawals"
            name="WithdrawalsTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            columns={withdrawalColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.Trades.Key) !== -1}
            database={"Trade"}
            searchEndpoint={"/admin-history/trades/"+userId}
            displayName="Trades"
            name="TradesTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={[]}
            columns={tradeColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.Subscriptions.Key) !== -1}
            database={"Merchant"}
            searchEndpoint={"/admin-history/subscriptions/"+userId}
            displayName="Subscriptions"
            name="SubscriptionsTable"
            sideItems={subscriptionsLeftItems}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={subscriptionsJoinOptions}
            columns={subscriptionsColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.Payments.Key) !== -1}
            database={"Merchant"}
            searchEndpoint={"/admin-history/payments/"+userId}
            displayName="Payments"
            name="PaymentsTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={paymentJoinOptions}
            columns={paymentColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
        <div>
          <Table 
            load={slidToArray.indexOf(SlideEnum.OpenOrders.Key) !== -1}
            database={"Trade"}
            searchEndpoint={"/admin-history/credit/"+userId}
            displayName="Credit"
            name="CreditTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={[]}
            columns={creditColumns}
            refreshInc={refreshInc}
            limit={10}/>
        </div>
      </Carousel>
    </div>
  );

};

export default WalletHistory;