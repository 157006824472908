import React, { useState, useEffect } from "react";
import "./users.scss";
import Button from "../../elements/button/button";
import { ReactComponent as PersonSvg } from "../../../css/imgs/icon-person.svg";

import useUserState from "../../../store/user";
import Table from '../../elements/table/table';
import AccountStatusEnum,{AccountStatusIdEnum} from "../../../enums/account-status";
import UserModal from "./user-modal";

import useSocketState from '../../../store/socket';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

const Users = () => {

  const { user } = useUserState();
  
  const [showUserModal, setShowUserModal] = useState(false);
  const [showUser, setShowUser] = useState({});
  const [refreshInc,setRefreshInc] = useState(0);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [tickers,setTickers] = useState({});

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Tickers] = SocketEnum.Tickers;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newTickers = tickers;

    try{
      if(socketMessages){
        if(SocketEnum.Tickers in socketMessages){
          let tickerRes = socketMessages[SocketEnum.Tickers][SocketEnum.Tickers];
          newTickers = tickerRes.res[SocketEnum.Tickers];
        }
      }
    }catch(e){
      console.log("catch error e",e);
    }
    
    setTickers(newTickers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages])

  const refresh = (refreshInc) => {
    setRefreshInc(refreshInc+1);
  }

  const sideItems = [{display:"Any",conditions:[]}];
  for (let key in AccountStatusEnum) {
    let accountStatus = AccountStatusEnum[key];
    if(AccountStatusEnum.Any === accountStatus) continue;
    sideItems.push({
      display: key,
      conditions:[{
        action: "=",
        column:"status", 
        table:"users",
        value: accountStatus,
      }]
    });
  }

  const columns = [
    {table: "users",value: "id",hide: true, index:true, },
    {table: "users",value: "uid",hide: true, index:true, },
    {table: "users",value: "username",default: true, index:true, 
      custom: ({v,cv}) => {
        return(
        <div className={"username-column"}>
          {/* {v.userBioDataProfilePictureId !== null ? (
            <img src={Config.Common.CloudFrontFull+v['userBioDataProfilePictureId']+".png"} alt={v.username}/>
          ):( */}
            <div className="svg-container"><PersonSvg /></div>
          {/* )} */}
          {v[cv]}
        </div>
      )},
    },
    {table: "users",value: "email", index:true, },
    {table: "users",value: "status", display:"User Status",index:true, 
      custom: ({v,cv}) => {
        let status = parseInt(v[cv]);
        let statusText = (status in AccountStatusIdEnum)?AccountStatusIdEnum[status]:status;
        return(
          <div>{statusText}</div>
        )
      },  
    },
    {table: "users",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
    {table: "users",value: "lastActive", type:"date",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"userAuthTokens",leftColumn:"id",rightColumn:"userId",},
  ]

  return (
    <div className="users-comp">
      <div className="users-comp-header">
        <h3>Users</h3>
        <Button
          status="add"
          className="button"
          onClick={() => {
            setShowUser(null);
            setShowUserModal(true);
          }}>
          New User
        </Button>
      </div>
      <Table 
        displayName="Users"
        name="UsersTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowUser(value);
          setShowUserModal(true);
        }}
        refreshInc={refreshInc}
        columns={columns}
        limit={250}
      />

      <UserModal
        user={user}
        show={showUserModal}
        handleClose={() => {
          setShowUser(null);
          setShowUserModal(false);
        }}
        refresh={() => {refresh(refreshInc);}}
        viewUser={showUser}
        tickers={tickers}
      />
    </div>
  );
};

export default Users;
