import React, { useState, } from "react";
import "./outputs.scss";
import * as DateUtil from "../../../utils/date";
import * as NumberUtil from "../../../utils/number";
import CoinImageBundle from '../../../css/imgs/coins/0-bundle';

import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";

const Outputs = () => {

  const [showOutput,setShowOutput] = useState(null);
  const [showOutputsModal,setShowOutputsModal] = useState(false);

  const sideItems = [
    {display: "All", conditions:[]},
    {display: "Local Unspent",conditions:[{
      column:"localSpent", 
      table:"outputs",
      action: "=",
      value: "0"
    }]},
    {display: "Local Spent", conditions:[{
      column:"localSpent", 
      table:"outputs",
      action: "=",
      value: "1"
    }]},
    {display: "Blockchain Unspent",conditions:[{
      column:"blockchainSpent", 
      table:"outputs",
      action: "=",
      value: "0"
    }]},
    {display: "Blochain Spent", conditions:[{
      column:"blockchainSpent", 
      table:"outputs",
      action: "=",
      value: "1"
    }]},
    {display: "Local Spent, Blockchain Unspent",conditions:[{
      column:"localSpent", 
      table:"outputs",
      action: "=",
      value: "1"
    },{
      column:"blockchainSpent", 
      table:"outputs",
      action: "=",
      value: "0"
    }]},
    {display: "Local Unspent, Blockchain Spent",conditions:[{
      column:"localSpent", 
      table:"outputs",
      action: "=",
      value: "0"
    },{
      column:"blockchainSpent", 
      table:"outputs",
      action: "=",
      value: "1"
    }]},
  ];

  const columns = [
    {table: "outputs",value: "coin", index:true, 
      custom: ({v,cv}) => {
        let coin = v[cv];
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
        <div className="coin-asset">
          <img src={coinSrc} />
          <div className="coin">{coin}</div>
        </div>);
      }
    },
    {table: "outputs",value: "valueSats", display:"Value",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "outputs",value: "id",hide: true, index:true,},
    {table: "outputs",value: "address",default: true, index:true,type:"copy-input", ignoreRowClick:true},
    {table: "outputs",value: "blockHeight", index:true,
      custom: ({v,cv}) => <div>{NumberUtil.numberWithCommas(v[cv])}</div>, 
    },
    {table: "outputs",value: "txId", hide:true, index:true, type:"copy-input", ignoreRowClick:true},
    {table: "outputs",value: "positionIndex",hide: true, index:true, },
    {table: "outputs",value: "scriptHex", hide: true },
    {table: "outputs",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "outputs",value: "localSpent", index:true,hide:true,  },
    {table: "outputs",value: "localSpentDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "outputs",value: "blockchainSpent", index:true, hide:true, },
    {table: "outputs",value: "blockchainSpentBlockheight", index:true, },
    {table: "outputs",value: "blockchainSpentDate", index:true, 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="outputs-comp">
      <div className="outputs-comp-header">
        <h3>Outputs</h3>
      </div>
      <Table 
        database={"Node"}
        displayName="Outputs"
        name="OutputsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowOutput(value);
          setShowOutputsModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="outputs-modal" show={showOutputsModal} handleClose={() => {setShowOutputsModal(false)}}>
        <div className="outputs-modal-main">
          <pre>
          {JSON.stringify(showOutput, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Outputs;
