import React,{useState,useEffect} from "react";
import "./stores.scss";
import * as DateUtil from "../../../utils/date";
import Table from '../../elements/table/table';
import StoresModal from './stores-modal';

import useSocketState from '../../../store/socket';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

const Stores = () => {

  const [show,setShow] = useState(false);
  const [store,setStore] = useState(null);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [tickers,setTickers] = useState({});

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Tickers] = SocketEnum.Tickers;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newTickers = tickers;

    try{
      if(socketMessages){
        if(SocketEnum.Tickers in socketMessages){
          let tickerRes = socketMessages[SocketEnum.Tickers][SocketEnum.Tickers];
          newTickers = tickerRes.res[SocketEnum.Tickers];
        }
      }
    }catch(e){
      console.log("catch error e",e);
    }
    
    setTickers(newTickers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages])

  const sideItems = [];

  const columns = [
    {table: "stores",value: "id", index:true, hide:true,},
    {table: "stores",value: "uid", index:true, hide:true,},
    {table: "stores",value: "creatorUserId", index:true, hide:true,},
    {table: "stores",value: "name", },
    {table: "users",value: "username", display:"Owner Username", ignore:true,
      custom: ({v,cv}) => <div>{(v['storeCreatorUsername']?v['storeOwnerUsername']:"---")}</div>,      
    },
    {table: "stores",value: "desc", hide:true,},
    {table: "stores",value: "website", },
    {table: "stores",value: "haltPayments", 
      custom: ({v,cv}) => <div>{(v[cv]?"True":"False")}</div>,  
    },
    {table: "stores",value: "haltPaymentsReason", hide:true, },
    {table: "stores",value: "useCustomCashback", hide:true,},
    {table: "stores",value: "customCashback", 
      custom: ({v,cv}) => {
        let useCustomCashback = parseInt(v['storesUseCustomCashback']);
        let customCashback = useCustomCashback?(v[cv]+"%"):"default";
        return(
          <div>
            {customCashback}
          </div>
        )
      },  
    },
    {table: "stores",value: "reward", },
    {table: "stores",value: "open",
      custom: ({v,cv}) => <div>{(v[cv]?"Open":"Closed")}</div>,  
    },
    {table: "stores",value: "openDate", type:"date", hide:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "stores",value: "archived", hide:true,
      custom: ({v,cv}) => <div>{(v[cv]?"True":"False")}</div>,  
    },
    {table: "stores",value: "archivedDate",  type:"date",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "stores",value: "createdDate", type:"date", index:true, defaultOrder:"DESC",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"issues",rightTable:"stores",leftColumn:"storeId",rightColumn:"id",},
  ]

  return (
    <div className="stores-comp">
      <div className="stores-comp-header">
        <h3>Stores</h3>
      </div>
      <Table 
        database={"Merchant"}
        searchEndpoint={"/store/any"}
        displayName="Stores"
        name="StoresTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {

        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setStore(value);
          setShow(true);
        }}
        columns={columns}
        limit={250}
      />

      <StoresModal show={show} store={store} handleClose={() => {setShow(false)}} tickers={tickers} />

    </div>
  );
};

export default Stores;
