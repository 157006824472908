import React from "react";

const StoreProducts = () => {

  return (
    <div className="store-products-comp">
      store-products-comp
    </div>
  );
};

export default StoreProducts;
