
import React, { useState, } from "react";
import "./customer-support.scss";
import Table from '../../elements/table/table';
import CSModal from './cs-modal';
import CopyInput from "../../elements/copy-input/copy-input";

const CustomerSupport = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);
  
  const sideItems = [
    // {display: "All", conditions:[]},
    {display: "Open", conditions:[{
      column:"status", table:"tickets", action: "=",value: "open",
    }]},
    {display: "Closed", conditions:[{
      column:"status", table:"tickets", action: "=",value: "closed",
    }]},
  ];

  const columns = [
    {table: "tickets",value: "id",hide: true,},
    {table: "users",value: "username",hide: true,},
    {table: "tickets",value: "uid",hide:true,},
    {table: "tickets",value: "userId", ignoreRowClick: true,
      custom: ({v,cv}) => {
        let userId = v[cv];
        let username = v["usersUsername"];
        return(
          <div>
            {/* <CopyInput display={"Username"} value={username} />  
            <CopyInput display={"User Id"} value={userId} />   */}
            <CopyInput value={username} />  
            <CopyInput value={userId} />  
          </div>
        )
      }, 
    },
    {table: "tickets",value: "status",},
    {table: "tickets",value: "type",},
    {table: "tickets",value: "staffUserId",},
    {table: "tickets",value: "needsReply", 
    custom: ({v,cv}) => {
      return(
        <div>{v[cv] === 1?"True":"False"}</div>
      )
    }, 
    },
    {table: "tickets",value: "createdDate", type:"date", index:true, defaultOrder:"DESC",},
    {table: "tickets",value: "updatedDate", type:"date",},
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"tickets",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

  return (
    <div className="customer-support-comp">
      <div className="customer-support-comp-header">
        <h3>Customer Support</h3>
      </div>
      <Table 
        database={"Main"}
        displayName="CustomerSupport"
        name="CustomerSupportTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value.ticketsUid);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <CSModal 
        show={showModal} 
        handleClose={() => {setShowModal(false)}}
        ticketUid={showValue}
        />

    </div>
  );
};

export default CustomerSupport;
