import React,{useState,useEffect,useRef} from 'react';
import './send.scss';
import { request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import ReactSelect from 'react-select';
import {CoinEnum} from '../../../enums/coins';
import CoinImageBundle from '../../../css/imgs/coins/0-bundle';
import * as ReactSelectUtil from '../../../utils/react-select';
import Buttib from '../../elements/button/button';

const ReactSelectOptions = []
const skipCoins = ["USD"];
for (let key in CoinEnum) {
  if(skipCoins.indexOf(key) !== -1) continue;
  ReactSelectOptions.push({
    value: key,
    label: (
      <div className="coin-label">
        <img src={CoinImageBundle[key]} alt={key}/>
        <div className="text">{key}</div>
      </div>),
  })
}

const Send = ({}) => {

  const [coin,setCoin] = useState("");
  const [amount,setAmount] = useState("");
  const [address,setAddress] = useState("");
  const [totp,setTotp] = useState("");
  const [loading,setLoading] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    send()
  }

  const send = () => {

    let data = {
      coin,
      amount,
      address,
      totp,
    }

    setLoading(true);
    request("admin-send","/admin-send","POST", data, {
      then: function(res){
        console.log("res",res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  useEffect(() => {
    console.log("coin",coin);
  },[coin])

  return (
    <div className="send-comp">
      <h3>Admin Send</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Network</label>
          <ReactSelect 
            className="react-select-coins"
            classNamePrefix="custom-react-select-inner"
            onChange={(e) => {
              setCoin(e.value);
            }}
            value={ReactSelectUtil.buildObjectByValues([coin],ReactSelectOptions)}
            options={ReactSelectOptions}
            />
        </div>
        <div>
          <label>Amount</label>
          <input type="text" value={amount} onChange={(e) => {setAmount(e.target.value)}} />
        </div>
        <div>
          <label>Address</label>
          <input type="text" value={address} onChange={(e) => {setAddress(e.target.value)}} />
        </div>
        <div>
          <label>Totp</label>
          <input type="text" value={totp} onChange={(e) => {setTotp(e.target.value)}} />
        </div>

        <Buttib status={loading?"loading":""} type="submit" className='button' value="Send" />
      </form>
    </div>
  );

};

export default Send;