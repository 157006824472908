import React, { useState, useEffect } from "react";
import "./totals.scss";
import * as DateUtil from "../../../utils/date";
import * as NumberUtil from "../../../utils/number";
import * as TickersUtil from '../../../utils/tickers';
import {UsdCoins} from '../../../enums/coins';

import Table from '../../elements/table/table';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import Send from "./send";

import useSocketState from '../../../store/socket';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

import CoinImageBundle from '../../../css/imgs/coins/0-bundle';
import {CoinEnum} from "../../../enums/coins";

const Totals = () => {

  const [slide,setSlide] = useState(1);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [tickers,setTickers] = useState({});

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Tickers] = SocketEnum.Tickers;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newTickers = tickers;

    try{
      if(socketMessages){
        if(SocketEnum.Tickers in socketMessages){
          let tickerRes = socketMessages[SocketEnum.Tickers][SocketEnum.Tickers];
          newTickers = tickerRes.res[SocketEnum.Tickers];
        }
      }
    }catch(e){
      console.log("catch error e",e);
    }
    
    setTickers(newTickers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages])

  const sideItems = [
    // {display: "All", conditions:[]},
    // {display: "Unspent",conditions:[{
    //   column:"localSpent", 
    //   table:"outputs",
    //   action: "=",
    //   value: "0"
    // }] },
    // {display: "Spent", conditions:[{
    //   column:"localSpent", 
    //   table:"outputs",
    //   action: "=",
    //   value: "1"
    // }]},
  ];

  const totalCreditsColumns = [
    {table: "totalCredits",value: "coin", index:true, 
      custom: ({v,cv}) => {
        let coin = v.totalCreditsCoin;
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
          <div className={"coin-left"}>
            <img src={coinSrc}/>
            <div>{coin}</div>
          </div>
        )
      },  
    },
    {table: "totalCredits",value: "availableSats", display:"Available",
      custom: ({v,cv}) => {
        let coin = v.totalCreditsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },  
    },
    {table: "totalCredits",value: "frozenSats", display:"Frozen",
      custom: ({v,cv}) => {
        let coin = v.totalCreditsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    {table: "totalCredits",value: "createdDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "totalCredits",value: "updatedDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ];

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

 

  const nodeOutputsColumns = [
    {table: "totalOutputs",value: "coin", index:true, 
      custom: ({v,cv}) => {
        let coin = v.totalOutputsCoin;
        let coinSrc = "";
        if(coin in CoinImageBundle){
          coinSrc = CoinImageBundle[coin];
        }
        return(
          <div className={"coin-left"}>
            <img src={coinSrc}/>
            <div>{coin}</div>
          </div>
        )
      },  
    },
    {table: "totalOutputs",value: "availableSats", display:"Available",
      custom: ({v,cv}) => {
        let coin = v.totalOutputsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },  
    },
    {table: "totalOutputs",value: "frozenSats", display:"Frozen",
      custom: ({v,cv}) => {
        let coin = v.totalOutputsCoin;
        let amountSats = v[cv];
        let {btcValueSats,usdValueCents} = TickersUtil.estimateCoinValue(coin, amountSats, tickers);
        return(
          <div className="amount">
            <div>{NumberUtil.satsToVal(amountSats)} {coin}</div>
            {(coin !== "BTC")?<div className="sub">~ {NumberUtil.satsToVal(btcValueSats)} BTC</div>:null}
            {(UsdCoins.indexOf(coin) === -1)?<div className="sub">~ {NumberUtil.centsToVal(usdValueCents)} USD</div>:null}
          </div>
        )
      },   
    },
    {table: "totalOutputs",value: "createdDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "totalOutputs",value: "updatedDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ];
  

  return (
    <div className="totals-comp">
      <div className="totals-comp-header">
        <h3>Totals</h3>
      </div>
      <CarouselHeader slideTo={slide}>
        <div onClick={() => { setSlide(1);}}>Total Credits</div>
        <div onClick={() => { setSlide(2);}}>Node Outputs</div>
        <div onClick={() => { setSlide(3);}}>Send</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>
          <Table 
            database={"Trade"}
            displayName="Total Credits"
            name="TotalCreditsTable"
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {

            }}
            columns={totalCreditsColumns}
            limit={250}
            />
        </div>
        <div>
          <Table 
            database={"Node"}
            displayName="Total Outputs"
            name="TotalOutputsTable"
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {

            }}
            columns={nodeOutputsColumns}
            limit={250}
            />
        </div>
        <div>
          <Send />
        </div>
      </Carousel>
      

    </div>
  );
};

export default Totals;
