

import BTC from './BTC.png';
import ETH from './ETH.png';
import LTC from './LTC.png';
import BCH from './BCH.png';
import DAWI from './DAWI.png';
import DASH from './DASH.png';
import DOGE from './DOGE.png';
import USD from './USD.png';
import USDT from './USDT.png';
import USDC from './USDC.png';
import ADA from './ADA.png';

import BTCbig from './BTC-big.png';
import ETHbig from './ETH-big.png';
import LTCbig from './LTC-big.png';
import BCHbig from './BCH-big.png';
import DAWIbig from './DAWI-big.png';
import DASHbig from './DASH-big.png';
import DOGEbig from './DOGE-big.png';

const CoinImageBundle = {
  BTC,
  ETH,
  LTC,
  BCH,
  DAWI,
  DASH,
  DOGE,
  USD,
  USDT,
  USDC,
  ADA,
}
export const CoinBigImageBundle = {
  BTC: BTCbig,
  ETH: ETHbig,
  LTC: LTCbig,
  BCH: BCHbig,
  DAWI: DAWIbig,
  DASH: DASHbig,
  DOGE: DOGEbig,
}

export default CoinImageBundle;